/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Progress,
  Button
} from "reactstrap";

import Switch from "react-bootstrap-switch";
import Datetime from "react-datetime";
import { Storage } from 'aws-amplify';
import uuid from "uuid";

const workExp = [
  {
    label: "1. Previous company name",
    ref: React.createRef()
  },
  {
    label: "2. Previous company name",
    ref: React.createRef()
  }
];

class Education extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      workExperience: [
      ],
      otherWorkExperience: "",
      lifeguardCert: false
      
    };

    this.valueChange = this.valueChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
  }

  reference = React.createRef();
  reference1 = React.createRef();

  handleSwitch(elem, state) {
    this.setState({[elem.props.name]: state });
  }

  handleDateChange(field, date, index) {
    let state = Object.assign({}, this.state);
    state.workExperience[index][field] = date.valueOf();
    this.setState(state);
   }

  
  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  

  isValidated() {
    return true;
  }

  // // // to understand the bellow functions please take a look at the end of the document as well
  // this handles the onFocus event over the normal / visible Inputs (reactstrap components)
  // that will trigger the on click of the non-visible inputs (normal html component)
  handleFileInput = (e, index) => {
    workExp[index].ref.current.click(e);
  };
  // this is the function triggered for the file type input
  // it will store inside the components state the names of the files and the files
  // after that, if you want to save the files you need to add a special on submit function for that
  // we haven't since we do not need to save the files - everything in our product is only front-end
  addFile = (e, index, label) => {
    let files = e.target.files;
    this.saveFile(files[0], index);

    let state = Object.assign({}, this.state);
    state.workExperience[index] ? state.workExperience[index].fileName = files[0].name : state.workExperience[index] = { fileName: files[0].name};
    //state.workExperience[index].label = label;
    this.setState(state);
  };

  


  saveFile =  (file, index) => {
    console.log("Save File");
    const parentThis = this;

    // console.log(this.props);
    // console.log(this.state);
    const customPrefix = {
      public: this.state.id+'/'
      // protected: 'myProtectedPrefix/',
      // private: 'myPrivatePrefix/'
    };
    let fileName = uuid.v4();
    Storage.put(fileName, file, {
      level: 'public',   //default
      customPrefix: customPrefix,
      contentDisposition: "attachment; filename="+file.name+"",
      progressCallback(progress) {
        let progressValue = Math.round(progress.loaded / progress.total * 100);
        let state = Object.assign({}, parentThis.state);
        state.workExperience[index] ? state.workExperience[index].progress = progressValue : state.workExperience[index] = { progress: progressValue};       
        parentThis.setState(state);
      }
    } )
    .then (result => {
      console.log("Save File Result : "+JSON.stringify(result)) 
      let state = Object.assign({}, this.state);
      state.workExperience[index] ? state.workExperience[index].fileName = file.name : state.workExperience[index] = { fileName: file.name};
      state.workExperience[index].s3FileName = fileName;
      this.setState(state);
  
    })
    .catch(err => {
      console.log(err)
    })
  }

  handleDownload = async (event, index) => {
    event.preventDefault();
    //console.log("Download File "+JSON.stringify(this.state.documents[index]));
    const customPrefix = {
      public: this.state.id+'/'
      // protected: 'myProtectedPrefix/',
      // private: 'myPrivatePrefix/'
    };
    Storage.get(this.state.workExperience[index].s3FileName, 
        {
          expires: 60,
          customPrefix: customPrefix
        })
      .then(result => {
          console.log(result)
          //let blob=new Blob([data.Body], {type: data.ContentType});
          let link=document.createElement('a');
          link.href=result;
          link.download=result;
          link.click();
      
      
      })
      .catch(err => console.log(err));
  }






  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    // if (nextProps.firstname !== this.state.firstname) {
    //   this.setState({ firstname: nextProps.firstname });
    // }
    // console.log("EM CReceviing props "+JSON.stringify(nextProps));

    //let state = Object.assign({}, this.state);

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      let newProps = Object.assign({}, nextProps);
      delete newProps.user;
      delete newProps.disabled;
      this.setState({ ...newProps });
    }
  }


  render() {
    return (
      <>
        <h5 className="info-text">
          {" "}
          Skills - Previous employment starting with most recent.
        </h5>
     
        {workExp.map(( we, index ) => {
        return (
        <Row key={index} className="justify-content-center">
          <Col xs={12} sm={6}>
            <FormGroup>
              <Label>{we.label}</Label>
              <Input
                  value={this.state.workExperience[index] ? this.state.workExperience[index].companyName : "" || ''}
                  disabled={this.props.disabled}
                  placeholder="Company Name"
                  onChange={e => {
                      let state = Object.assign({}, this.state);
                      state.workExperience[index] ? state.workExperience[index].companyName = e.target.value : state.workExperience[index] = { companyName: e.target.value};
                      this.setState(state);
                    }
                  }
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={2}>
            <FormGroup>
              <Label>Start Date</Label>
              <Datetime
                       value={this.state.workExperience[index] ? this.state.workExperience[index].startDate : ""|| ''}
                       timeFormat={false}
                       closeOnSelect={true}
                       dateFormat="YYYY/MM/DD"
                       inputProps={{ placeholder: "Start Date", disabled:this.props.disabled }}
                       onChange={v => {
                          let state = Object.assign({}, this.state);
                          state.workExperience[index] ? state.workExperience[index].startDate = v : state.workExperience[index] = { startDate: v};
                          this.setState(state);
                        }
                      }
                    />
            </FormGroup>
          </Col>
          <Col xs={12} sm={2}>
            <FormGroup>
              <Label>End Date</Label>
              <Datetime
                       value={this.state.workExperience[index] ? this.state.workExperience[index].endDate : "" || ''}
                       timeFormat={false}
                       closeOnSelect={true}
                       dateFormat="YYYY/MM/DD"
                       inputProps={{ placeholder: "End Date", disabled:this.props.disabled  }}
                       onChange={v => {
                          let state = Object.assign({}, this.state);
                          state.workExperience[index] ? state.workExperience[index].endDate = v : state.workExperience[index] = { endDate: v};
                          this.setState(state);
                        }
                      }
                  />
            </FormGroup>
          </Col>
          <Col xs={12} sm={6}>
            <FormGroup>
              <Label>Position/Job Title</Label>
              <Input
                  value={this.state.workExperience[index] ? this.state.workExperience[index].jobTitle : "" || ''}
                  disabled={this.props.disabled}
                  placeholder="Job Title"
                  name="jobTitle"
                  onChange={e => {
                    let state = Object.assign({}, this.state);
                    state.workExperience[index] ? state.workExperience[index].jobTitle = e.target.value : state.workExperience[index] = { jobTitle: e.target.value};
                    this.setState(state);
                  }
                }
              />
              </FormGroup>
          </Col>
          <Col xs={12} sm={4}>
            <FormGroup>
              <Label>Reference from this employer</Label>
              <br/>
              <Input
                    type="text"
                    disabled={this.props.disabled}
                    className="inputFileVisible"
                    placeholder="Upload Reference..."
                    onClick={e => this.handleFileInput(e, index)}
                    onChange={e => e.preventDefault()}
                    value={this.state.workExperience[index] ? this.state.workExperience[index].fileName : ""}
                />
              <input
                    type="file"
                    disabled={this.props.disabled}
                    className="inputFileHidden"
                    style={{ zIndex: -1 }}
                    ref={we.ref}
                    onChange={e => this.addFile(e, index, we.label)}
                        />
                { this.state.workExperience[index] && this.state.workExperience[index].progress > 0  && this.state.workExperience[index].progress !== 100 &&
                  <div className="progress-container progress-primary">
                        <span className="progress-badge">Upload Progress</span>
                        <Progress max="100" value={this.state.workExperience[index].progress}>
                            <span className="progress-value">{this.state.workExperience[index].progress}%</span>
                        </Progress>
                  </div>
                }
                { this.state.workExperience[index] && this.state.workExperience[index].progress === 100 &&
                  <div className="btns-mr-5">
                    <Button block color="primary"
                        onClick={e => this.handleDownload(e, index)}
                        size="sm"
                    >Download</Button>
                  </div>
                }                 
              </FormGroup>
          </Col>
        </Row>
         )})
        }
        <Row className="justify-content-center">
          <Col xs={12} sm={7}>
            <FormGroup>
              <Label>Please describe any other work or volunteer experiences you have had that will help you with a job in the U.S.</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.otherWorkExperience || ''}
                  placeholder="Other work experience"
                  name="otherWorkExperience"
                  onChange={this.valueChange}
                  type="textarea"
                  
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={3}>
            <FormGroup>
               <Label>Does applicant have a swimming/lifeguard or life certificate?</Label>
               <br/>
               <Switch defaultValue={false}
                disabled={this.props.disabled}
                value={this.state.lifeguardCert}
                name="lifeguardCert"
                onChange={(el, state) => this.handleSwitch(el, state)}
                onText="Yes"
                offText="No"/>
              </FormGroup>
          </Col>
        </Row>

      </>
    );
  }
}

export default Education;
