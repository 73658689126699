/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  FormFeedback
} from "reactstrap";
import { Auth } from "aws-amplify";
import SweetAlert from "react-bootstrap-sweetalert";

// core components

import logo from "assets/img/awe_logo_sm.png";

import bgImage from "assets/img/bg14.jpg";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      alert: null,
      password: "",
      errors: {
        cognito: null,
        blankfield: false
      }
    };

    this.hideAlert = this.hideAlert.bind(this);
  }


  hideAlert() {
    this.setState({
      alert: null
    });
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false
      }
    });
  };

  autoCloseAlert() {
    
    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Logon Failed!"
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
          
        >
          {this.state.errors.cognito ? this.state.errors.cognito.message : this.state.errors}
        </SweetAlert>
      )
    });
    setTimeout(this.hideAlert, 3000);
  }

  handleSubmit = async event => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    //const error = Validate(event, this.state);
    // if (error) {
    //   this.setState({
    //     errors: { ...this.state.errors, ...error }
    //   });
    // }


    
    // AWS Cognito integration here
    try {
      const user = await Auth.signIn(this.state.email, this.state.password);
      console.log(user);
      user["email"] = this.state.email;
      user["shortEmail"] = this.state.email.substring(0, this.state.email.indexOf("@"));
      this.props.auth.setAuthStatus(true);
      this.props.auth.setUser(user);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.props.history.push({
          pathname: '/auth/change-pass'
        });
      } else {
        console.log("UserType : "+JSON.stringify(user.attributes));

        if(user.attributes["custom:userType"] === "ADMIN") {
          this.props.history.push("/admin");
        } else if(user.attributes["custom:userType"].indexOf("#APPLICANT") !== -1) {
          this.props.history.push("/appl");
        } else if(user.attributes["custom:userType"].indexOf("#REP") !== -1) {
          this.props.history.push("/rep");
        }
      }
      
    }catch(error) {
      console.log(error);
      let err = null;
      !error.message ? err = { "message": error } : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }        
      });
      this.autoCloseAlert.bind(this);
      this.autoCloseAlert();
    }
  };


  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  emailChanged = event =>  {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(event.target.value)) {
      this.setState({
        emailValid: true,
        [event.target.id]: event.target.value
      });
    } else {
      this.setState({
        emailValid: false,
        [event.target.id]: event.target.value
      });

    }

  }


  



  componentDidMount() {
    document.body.classList.add("login-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("login-page");
  }



  render() {
    return (
      <>
        <div className="content">
          {this.state.alert}
          <div className="login-page">
            <Container>
              <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                
                <Form>
                  <Card className="card-login card-plain">
                    <CardHeader>
                      <div className="logo-container">
                        <img src={logo} alt="now-logo" />
                      </div>
                      <h6 style={{color: "white"}}>
                         Please sign in
                      </h6>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={
                          "no-border form-control-lg " +
                          (this.state.emailFocus ? "input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          valid={this.state.emailValid}
                          type="text"
                          autoComplete="username"
                          id="email"
                          placeholder="Email Address..."
                          // onFocus={e => this.setState({ emailFocus: true })}
                          // onBlur={e => this.setState({ emailFocus: false })}
                          value={this.state.email}
                          onChange={this.emailChanged}
                        />
                        <FormFeedback>You will not be able to see this</FormFeedback>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border form-control-lg " +
                          (this.state.passwordFocus ? "input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          valid={this.state.password !== ""}
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          placeholder="Password..."
                          // onFocus={e => this.setState({ passwordFocus: true })}
                          // onBlur={e => this.setState({ passwordFocus: false })}
                          value={this.state.password}
                          onChange={this.onInputChange}
                        />
                        <FormFeedback>You will not be able to see this</FormFeedback>
                      </InputGroup>
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        color="primary"
                        size="lg"
                        onClick={this.handleSubmit}
                        className="mb-3 btn-round"
                      >
                        Logon
                      </Button>
                      <div className="pull-left">
                        <h6>
                          <a href="forgot-password" className="link footer-link">
                            Forgot Password
                          </a>
                        </h6>
                      </div>
                      <div className="pull-right">
                        <h6>
                          <a href="#pablo" className="link footer-link">
                            Need Help?
                          </a>
                        </h6>
                      </div>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />
      </>
    );
  }
}

export default LoginPage;
