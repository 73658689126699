/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Progress,
  Button

} from "reactstrap";

import { Storage } from 'aws-amplify';
import uuid from "uuid";

import Datetime from "react-datetime";
//import { doExpression } from "@babel/types";

const docs = [
  {
    label: "Signed AWE Application form",
    placeHolder: "Click here to upload form",
    ref: React.createRef(),
    required: true
  },
  {
    label: "Medical Summary Report",
    placeHolder: "Click here to upload form",
    ref: React.createRef(),
    required: true
  },
  {
    label: "Passport Page",
    placeHolder: "Click here to upload form",
    ref: React.createRef(),
    required: true
  },
  {
    label: "Confirmation of the Student Status from University",
    placeHolder: "Click here to upload form",
    ref: React.createRef(),
    required: true
  },
  {
    label: "English Tests Forms Document",
    placeHolder: "Click here to upload form",
    ref: React.createRef(),
    required: true
  },
  {
    label: "Offers of Placement Letter",
    placeHolder: "Click here to upload form",
    ref: React.createRef(),
    required: true
  },
  {
    label: "Other 1",
    docTypeRequired: true,
    docTypeLabel: "Other Document 1 Description",
    docTypePlaceHolder: "Document Description",
    placeHolder: "Click here to upload document",
    ref: React.createRef(),
    required: false
  },
  {
    label: "Other 2",
    docTypeRequired: true,
    docTypeLabel: "Other Document 2 Description",
    docTypePlaceHolder: "Document Description",
    placeHolder: "Click here to upload document",
    ref: React.createRef(),
    required: false
  },
  {
    label: "Other 3",
    docTypeRequired: true,
    docTypeLabel: "Other Document 3 Description",
    docTypePlaceHolder: "Document Description",
    placeHolder: "Click here to upload document",
    ref: React.createRef(),
    required: false
  }
];

class ParticipantAgreement extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      signName: "",
      dateSigned: "",
      documents: []
    };

    this.valueChange = this.valueChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(field, date) {
    this.setState({ [field]: date.valueOf() });
   }

  
  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  isValidated() {
    let result = true;
    docs.map(( doc, index ) => {
      //console.log((!this.state.documents[index] || !this.state.documents[index].fileName || this.state.documents[index].fileName ==="" ));
      if((doc.required === true && this.props.user.rep === true) && (!this.state.documents[index] || !this.state.documents[index].fileName || this.state.documents[index].fileName ==="" )) {
        result = false;
      }
      return null;
    });

    if(this.state.signName === "" || this.state.dateSigned === "") {
      result = false;
    }

    return (result || this.props.disabled);
  }

  // // // to understand the bellow functions please take a look at the end of the document as well
  // this handles the onFocus event over the normal / visible Inputs (reactstrap components)
  // that will trigger the on click of the non-visible inputs (normal html component)
  handleFileInput = (e, index) => {
    docs[index].ref.current.click(e);
  };
  // this is the function triggered for the file type input
  // it will store inside the components state the names of the files and the files
  // after that, if you want to save the files you need to add a special on submit function for that
  // we haven't since we do not need to save the files - everything in our product is only front-end
  addFile = (e, index, label) => {
    let files = e.target.files;
    this.saveFile(files[0], index);

    let state = Object.assign({}, this.state);
    state.documents[index] ? state.documents[index].fileName = files[0].name : state.documents[index] = { fileName: files[0].name};
    state.documents[index].label = label;
    this.setState(state);
  };

  


  saveFile =  (file, index) => {
    console.log("Save File");
    const parentThis = this;

    // console.log(this.props);
    // console.log(this.state);
    const customPrefix = {
      public: this.state.id+'/'
      // protected: 'myProtectedPrefix/',
      // private: 'myPrivatePrefix/'
    };
    let fileName = uuid.v4();
    Storage.put(fileName, file, {
      level: 'public',   //default
      customPrefix: customPrefix,
      contentDisposition: "attachment; filename="+file.name+"",
      progressCallback(progress) {
        let progressValue = Math.round(progress.loaded / progress.total * 100);
        let state = Object.assign({}, parentThis.state);
        state.documents[index] ? state.documents[index].progress = progressValue : state.documents[index] = { progress: progressValue};       
        parentThis.setState(state);
      }
    } )
    .then (result => {
      console.log("Save File Result : "+JSON.stringify(result)) 
      let state = Object.assign({}, this.state);
      state.documents[index] ? state.documents[index].fileName = file.name : state.documents[index] = { fileName: file.name};
      state.documents[index].s3FileName = fileName;
      this.setState(state);
  
    })
    .catch(err => {
      console.log(err)
    })
  }

  handleDownload = async (event, index) => {
    event.preventDefault();
    //console.log("Download File "+JSON.stringify(this.state.documents[index]));
    const customPrefix = {
      public: this.state.id+'/'
      // protected: 'myProtectedPrefix/',
      // private: 'myPrivatePrefix/'
    };
    Storage.get(this.state.documents[index].s3FileName, 
        {
          expires: 60,
          customPrefix: customPrefix
        })
      .then(result => {
          console.log(result)
          //let blob=new Blob([data.Body], {type: data.ContentType});
          let link=document.createElement('a');
          link.href=result;
          link.download=result;
          link.click();
      
      
      })
      .catch(err => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    // if (nextProps.firstname !== this.state.firstname) {
    //   this.setState({ firstname: nextProps.firstname });
    // }
    // console.log("EM CReceviing props "+JSON.stringify(nextProps));

    //let state = Object.assign({}, this.state);

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      let newProps = Object.assign({}, nextProps);
      delete newProps.user;
      delete newProps.disabled;
      this.setState({ ...newProps });
    }
  }


  render() {
    return (
      <>
        <h5 className="info-text">
          {" "}
          Participant Agreement
        </h5>
     
        
        {docs.map(( doc, index ) => {
          let docTypeRequired = doc.docTypeRequired ? doc.docTypeRequired : false;
          let success = "has-success";
          let danger = "has-danger";
          if(doc.required === false) {
            success = "";
            danger = "";
          }

                      return (
                              <Row key={index} className="justify-content-center">
                                { docTypeRequired &&
                                  <Col xs={12} sm={5}>
                                    <FormGroup >
                                    <Label>{doc.docTypeLabel}</Label>
                                    <Input
                                        disabled={this.props.disabled}
                                        value={this.state.documents[index] ? this.state.documents[index].docLabel : ""}
                                        placeholder={doc.docTypePlaceHolder}
                                        onChange={e => {
                                          let state = Object.assign({}, this.state);
                                          state.documents[index] ? state.documents[index].docLabel = e.target.value : state.documents[index] = { docLabel: e.target.value};
                                          this.setState(state);
                                        }
                                      }
                                      />
                                    </FormGroup>
                                  </Col>
                                }
                              <Col xs={12} sm={ docTypeRequired ? 5 : 10}>
                                <FormGroup className={"has-label " + ((this.state.documents[index] && this.state.documents[index].fileName && this.state.documents[index].fileName !== '') ? success : (this.props.user.applicant === true ? "" : danger)  )}>
                                  <Label>{doc.label}</Label>
                                  <br/>
                                  <Input
                                        disabled={this.props.disabled}
                                        type="text"
                                        className="inputFileVisible"
                                        placeholder={doc.placeHolder}
                                        onClick={e => this.handleFileInput(e, index)}
                                        onChange={e => e.preventDefault()}
                                        value={this.state.documents[index] ? this.state.documents[index].fileName : ""}
                                    />
                                  <input
                                        disabled={this.props.disabled}
                                        type="file"
                                        className="inputFileHidden"
                                        style={{ zIndex: -1 }}
                                        ref={doc.ref}
                                        onChange={e => this.addFile(e, index, doc.label)}
                                            />
                                    { this.state.documents[index] && this.state.documents[index].progress > 0  && this.state.documents[index].progress !== 100 &&
                                      <div className="progress-container progress-primary">
                                            <span className="progress-badge">Upload Progress</span>
                                            <Progress max="100" value={this.state.documents[index].progress}>
                                                <span className="progress-value">{this.state.documents[index].progress}%</span>
                                            </Progress>
                                      </div>
                                    }
                                    { this.state.documents[index] && this.state.documents[index].progress === 100 &&
                                      <div className="btns-mr-5">
                                        <Button block color="primary"
                                            onClick={e => this.handleDownload(e, index)}
                                            size="sm"
                                        >Download</Button>
                                      </div>
                                    } 
                                  </FormGroup>
                              </Col>
                              </Row>
                        )})

        }

        <Row className="justify-content-center">
          <Col xs={12} sm={10}>
            <p>I have read, understood and agree to abide by the conditions of the application and placement as detailed in the American Work Experience (AWE) 2020 Terms and Conditions, which is part of the 2020 AWE application and application process. By signing the Programme Application, I have agreed to the complete contents of the 2020 AWE Terms and Conditions. My attention has been drawn to clause 57 from the aforementioned Terms and Conditions. I have completed this form fully and honestly and agree to return to my home country at the conclusion of my program and inform AWE of my return to my home country.</p>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup className={"has-label " + (this.state.signName !== "" ? "has-success" : "has-danger") }>
               <Label>By typing your name here, you are legally signing this application</Label>
               <Input
                  disabled={this.props.disabled}
                  value={this.state.signName || ''}
                  placeholder="Sign Name"
                  name="signName"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
          {/* <Col xs={12} sm={10}>
            <FormGroup>
               <Label>AWE ID#:</Label>
               <Input type="text" disabled/>
              </FormGroup>
          </Col> */}
          <Col xs={12} sm={10}>
            <FormGroup className={"has-label " + (this.state.dateSigned !== "" ? "has-success" : "has-danger")}>
              <Label>Date:</Label>
              <Datetime
                      timeFormat={false}
                      dateFormat="YYYY/MM/DD"
                      onChange={(e) => this.handleDateChange("dateSigned", e)}
                      value={this.state.dateSigned || ''}
                      inputProps={{ placeholder: "Select Date", disabled:this.props.disabled }}
                    />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default ParticipantAgreement;
