/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import AdminDashboard from "views/Dashboard/AdminDashboard.jsx";
import RepPage from "views/Pages/RepPage.jsx";
import RepListPage from "views/Pages/RepListPage.jsx";
import AppListPage from "views/admin/AppListPage.jsx";
// import EmpPage from "views/Pages/EmpPage.jsx";
// import EmpListPage from "views/Pages/EmpListPage.jsx";
import Wizard from "views/Forms/Wizard/Wizard.jsx";


let adminRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: "now-ui-icons design_app",
  //   component: AdminDashboard,
  //   layout: "/admin"
  // },
  {
    collapse: true,
    path: "/pages",
    name: "Reps",
    state: "openReps",
    icon: "now-ui-icons design_image",
    views: [
      {
        path: "/rep-list-page",
        name: "List Reps",
        mini: "LP",
        component: RepListPage,
        layout: "/admin"
      },
      {
        path: "/rep-page",
        name: "Create Rep",
        mini: "CP",
        component: RepPage,
        layout: "/admin"
      }
    ]
  },
  {
    collapse: true,
    path: "/admin",
    name: "Applicants",
    state: "openApplicants",
    icon: "now-ui-icons files_single-copy-04",
    views: [
      {
        path: "/app-list-page",
        name: "List Applicants",
        mini: "LA",
        component: AppListPage,
        layout: "/admin"
      }
    ]
  },
  {
    path: "/wizard",
    name: "Create Applicant",
    mini: "CA",
    component: Wizard,
    layout: "/admin",
    invisable: true
  }
  // {
  //   collapse: true,
  //   path: "/pages",
  //   name: "Employers",
  //   state: "openEmployers",
  //   icon: "now-ui-icons design_app",
  //   views: [
  //     {
  //       path: "/emp-list-page",
  //       name: "List Employers",
  //       mini: "LE",
  //       component: EmpListPage,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/emp-page",
  //       name: "Create Employer",
  //       mini: "CE",
  //       component: EmpPage,
  //       layout: "/admin"
  //     }
  //   ]
  // }
];

export default adminRoutes;
