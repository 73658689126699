
    /**
     * Find the layout for the appropiate user
     * 
     */
    export function findUserLayout(user) {
        let admin = user.attributes["custom:userType"].indexOf("ADMIN") !== -1;
        //let applicant = user.attributes["custom:userType"].indexOf("APPLICANT") !== -1;
        let rep = user.attributes["custom:userType"].indexOf("REP") !== -1 && user.attributes["custom:userType"].indexOf("APPLICANT") === -1;
        let layout = admin ? "/admin" : rep ? "/rep" : "/appl";

        return layout;
    
    }

    export default findUserLayout;

