/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";

import { Storage } from 'aws-amplify';


const appearances = [
  {
    type: "Formal"
  },
  {
    type: "Casual"
  },
  {
    type: "Conventional"
  },
  {
    type: "Modern"
  },
  {
    type: "Clean"
  },
  {
    type: "Tidy"
  },
  {
    type: "Presentable"
  }
];

const personalities = [
  {
    type: "Thoughtful"
  },
  {
    type: "Dynamic"
  },
  {
    type: "Self-Assured"
  },
  {
    type: "Cheerful"
  },
  {
    type: "Persistent"
  },
  {
    type: "Reserved"
  },
  {
    type: "Eager"
  },
  {
    type: "Talkative"
  },
  {
    type: "Dominant"
  },
  {
    type: "Adventurous"
  },
  {
    type: "Polite"
  },
  {
    type: "Serious"
  },
  {
    type: "Calm"
  },
  {
    type: "Persuasive"
  },
  {
    type: "Assertive"
  },
  {
    type: "Relaxed"
  },
  {
    type: "Tolerant"
  },
  {
    type: "Adaptable"
  },
  {
    type: "Responsive"
  },
  {
    type: "Outgoing"
  }

]


class Interview_3 extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      appearance: {},
      personality: {}
    };

    this.valueChange = this.valueChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  

  setProgressValue(pValue) {
    

    console.log("PValue "+this.state.progressValue);
  }

  handleDownload = async event => {
    event.preventDefault();
    console.log("Download File");
    Storage.get(this.state.file.name, {expires: 60})
      .then(result => {
          console.log(result)
          //let blob=new Blob([data.Body], {type: data.ContentType});
          let link=document.createElement('a');
          link.href=result;
          link.download=result;
          link.click();
      
      
      })
      .catch(err => console.log(err));
  }

  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  handleAppearance(e, type) {
    let val = this.state.appearance[type] === "Yes" ? "No" : "Yes";
    let state = Object.assign({}, this.state);
    state.appearance[type] = val;
    this.setState(state);
  }

  handlePersonality(e, type) {
    let val = this.state.appearance[type] === "Yes" ? "No" : "Yes";
    let state = Object.assign({}, this.state);
    state.personality[type] = val;
    this.setState(state);
  }

  

  isValidated() {
    
    return true;
  }

  handleDateChange(field, date) {
    this.setState({ [field]: date.valueOf() });
   }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      this.setState({ ...nextProps });
    }
  }


  render() {
    //console.log("********************* personal info rendering" + JSON.stringify(this.state));
    return (
      <>
        <h5 className="info-text">
          {" "}
          Apprearance / Personality
        </h5>
        <Row>
          <Col sm="10">
            <Label>APPEARANCE: Please check for any that apply:</Label>
          </Col>        
        </Row>
        <Row className="justify-content-center">        
            <Col sm="10">
            { appearances.map(( a, index ) => {
              return (
                <FormGroup key={index} check inline>
                  <Label check>
                    <Input 
                        type="checkbox" 
                        defaultValue="Yes"
                        checked={this.state.appearance[a.type] === "Yes"}
                        onChange={e => this.handleAppearance(e, a.type)}
                    />
                    <span className="form-check-sign" />{a.type}
                  </Label>
                </FormGroup>
                );
              })
            }
            </Col>
          </Row>
          <Row>
          <Col sm="10">
            <br/>
          </Col>
          <Col sm="10">
            <Label>PERSONALITY: Please check any of the following that apply:</Label>
          </Col>        
        </Row>
        <Row className="justify-content-center">        
            <Col sm="10">
            { personalities.map(( p, index ) => {
              return (
                <FormGroup key={index} check inline>
                  <Label check>
                    <Input 
                        type="checkbox" 
                        defaultValue="Yes"
                        checked={this.state.personality[p.type] === "Yes"}
                        onChange={e => this.handlePersonality(e, p.type)}
                    />
                    <span className="form-check-sign" />{p.type}
                  </Label>
                </FormGroup>
                );
              })
            }
            </Col>
          </Row>
          <Row className="justify-content-center">  
            <Col xs={12} sm={10}>
                <FormGroup>
                  <br/>
                  <Label>Main Skills</Label>
                  <Input 
                    value={this.state.mainSkills || ''}
                    name="mainSkills"
                    type="text"
                    placeholder="List Main Skills"
                    onChange={this.valueChange}
                  />
                </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">  
            <Col xs={12} sm={10}>
                <FormGroup>
                  <br/>
                  <Label>PREVIOUS EMPLOYMENT: Please confirm the jobs undertaken in the last 3 periods of employment</Label>
                  <Input 
                    value={this.state.employment || ''}
                    name="employment"
                    type="text"
                    placeholder="Employment"
                    onChange={this.valueChange}
                  />
                </FormGroup>
            </Col>
          </Row>
      </>
    );
  }
}

export default Interview_3;
