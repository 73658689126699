/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";


class EmergencyContact extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      name: "",
      relationship: "",
      email: "",
      homePhone: "",
      workPhone: "",
      mobile: "",
    };

    this.valueChange = this.valueChange.bind(this);
  }

  
  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
    // if (e.target.value.length > 2) {
    //   this.setState({
    //     lastnameState: " has-success"
    //   });
    // } else {
    //   this.setState({
    //     lastnameState: " has-danger"
    //   });
    // }
  }
  emailChange(e) {
    this.setState({
      email: e.target.value
    });
    // var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // if (emailRex.test(e.target.value)) {
    //   this.setState({
    //     emailState: " has-success"
    //   });
    // } else {
    //   this.setState({
    //     emailState: " has-danger"
    //   });
    // }
  }
  isValidated() {
    let result = true;
    if(this.state.name === "" || this.state.relationship === "" || this.state.homePhone === "") {
      result = false;
    }

    return result;
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    // if (nextProps.firstname !== this.state.firstname) {
    //   this.setState({ firstname: nextProps.firstname });
    // }
    // console.log("EM CReceviing props "+JSON.stringify(nextProps));

    //let state = Object.assign({}, this.state);

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      let newProps = Object.assign({}, nextProps);
      delete newProps.user;
      delete newProps.disabled;
      this.setState({ ...newProps });
    }
  }


  render() {
    return (
      <>
        <h5 className="info-text">
          {" "}
          Emergency Contact information
        </h5>
     
        <Row className="justify-content-center">
        <Col xs={12} sm={5}>
            <FormGroup className={"has-label " + (this.state.name !== "" ? "has-success" : "has-danger")}>
              <Label>Emergency Contact Name</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.name || ''}
                  placeholder="Name"
                  name="name"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup className={"has-label " + (this.state.relationship !== "" ? "has-success" : "has-danger")}>
              <Label>Emergency Contact Relationship to You</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.relationship || ''}
                  placeholder="Relationship"
                  name="relationship"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup className={"has-label " + (this.state.homePhone !== "" ? "has-success" : "has-danger")}>
              <Label>Emergency Contact Home Phone</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.homePhone || ''}
                  placeholder="Home Phone"
                  name="homePhone"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
              <Label>Emergency Contact Work Phone</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.workPhone || ''}
                  placeholder="Work Phone"
                  name="workPhone"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
              <Label>Emergency Contact Mobile Phone</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.mobile || ''}
                  placeholder="Mobile Phone"
                  name="mobile"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                  disabled={this.props.disabled}
                  defaultValue={this.state.email || ''}
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={e => this.emailChange(e)}
                />
              </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default EmergencyContact;
