/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";
import countryList from "react-select-country-list";
import Select from "react-select";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  FormGroup,
  Table,
  UncontrolledTooltip,
  CardTitle
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

const countryOptions = countryList().getData();

class RepPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        userType: "REP"
      },
      rep: {
        id: "",
        company: "",
        firstName: "",
        lastName: "",
        address: "",
        city: "",
        country: "",
        zip: "",
      },
      errors: {
        blankfield: false
      },
      disabled: true,
      userDisabled: true,
      users:[]
    };

    this.hideAlert = this.hideAlert.bind(this);
    this.notifcationAlert = this.notifcationAlert.bind(this);

  }

  notifcationAlert(success, title, message, timeout) {
    this.setState({
      alert: (
        <SweetAlert
          success={success}
          style={{ display: "block", marginTop: "-100px" }}
          title={title}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
        >
          {message}
        </SweetAlert>
      )
    });
    setTimeout(this.hideAlert, !timeout ? 3000 : timeout );
  }


  hideAlert() {
    this.setState({
      alert: null
    });
  }

  async currentUserToken() {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken.jwtToken;
  }
  

  handleUserSubmit = async event => {
    event.preventDefault();
    console.log("Handle user submit");



    // Auth.adminCreat({
    //   username,
    //   password,
    //   attributes: {
    //       email: this.state.user.email,          // optional
    //       "custom:tenantId" : "AWE",   
    //       "custom:userType" : "ADMIN"   
    //       // other custom attributes 
    //   },
    //   validationData: []  //optional
    //   })
    //   .then(data => console.log(data))
    //   .catch(err => console.log(err));
    let jwtToken = await Promise.resolve(this.currentUserToken());
    console.log("JKTToken "+jwtToken);


    this.state.user.userType = this.state.rep.id;

    console.log("Create user "+JSON.stringify(this.state.user));
    let apiName = config.api.API_NAME; 
    let path = "/createUser"; 
    let myInit = {
      body: {
          user: this.state.user
      }, 
      headers: {
        Authorization: jwtToken
      }
    }

    API.post(apiName, path, myInit).then(response => {
      console.log("Success id : "+ JSON.stringify(response));
      this.getUsers("", this.state.user.userType);
      this.notifcationAlert(true, "Updated Successfully", "Saved");
      this.setState({
        userDisabled: true,
        user: {
          id: "",
          firstName: "",
          lastName: "",
          email: "",
          userType: "REP"
        },
      });
    }).catch(error => {
      console.log(error);
      this.notifcationAlert(false, "Failed to update", error.response.data.error.message, 6000);
    });

    

  }

  handleSubmit = async event => {
    event.preventDefault();


    console.log("Submit rep");

    let jwtToken = await Promise.resolve(this.currentUserToken());
    console.log("JKTToken "+jwtToken);



    let apiName = config.api.API_NAME; 
    let path = "/awe_rep"; 
    let myInit = {
      body: {
          pk: "REP",
          sk: "REP",
          data: this.state.rep.country.label,
          rep: this.state.rep
      }, 
      headers: {
        Authorization: jwtToken
      }
    }

    API.post(apiName, path, myInit).then(response => {
      console.log("Success id : "+ response.id);
      this.notifcationAlert(true, "Updated Successfully", "Saved");
      let rep = this.state.rep;
      rep.id = response.id;
      this.setState({
        disabled: true,
        rep: rep
      });
    }).catch(error => {
      console.log(error)
    });

  }

  isValid(rep) {
    if(!rep.address || rep.address === "" 
        || !rep.company || rep.company === "" 
        || !rep.city || rep.city === "" 
        || !rep.country || rep.country === "" 
        || !rep.zip || rep.zip === "" 
        || !rep.firstName || rep.firstName === "" 
        || !rep.lastName || rep.lastName === "" ) {
      return false;
    } else {
      return true;
    }
  
  }
  
  onInputChange = event => {
    let rep = Object.assign({}, this.state.rep);
    rep[event.target.id] = event.target.value;

    let disabled = !this.isValid(rep);
    this.setState({
      rep: rep,
      disabled: disabled
    });
  };

  onCountryChange = country => {
    let rep = Object.assign({}, this.state.rep);
    rep['country'] = country;

    let disabled = !this.isValid(rep);

    this.setState({
      rep: rep,
      disabled: disabled
    });
  };


  onUserInputChange = event => {
    let user = Object.assign({}, this.state.user);
    user[event.target.id] = event.target.value;

    let disabled = false;
    if(!user.email || user.email === "" 
      || !user.firstName || user.firstName === "" 
      || !user.lastName || user.lastName === "" ) {
        disabled= true;
     } 

    this.setState({
      user: user,
      userDisabled: disabled
    });



  };

  async getUsers(token, userType) {
    //get users
    let jwtToken = token;
    if(!jwtToken || token === "") {
      jwtToken = await Promise.resolve(this.currentUserToken());
    }

    let apiName = config.api.API_NAME;
    let path2 = '/users'; 
    let myInit2 = { // OPTIONAL
          headers: {
            Authorization: jwtToken
          }, // OPTIONAL
          response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
          queryStringParameters: {  // OPTIONAL
            userType: userType
          }
        }
    API.get(apiName, path2, myInit2).then(response => {
      console.log("Users Respone : " + JSON.stringify(response));

      this.setState({ users: response.Users });
    }).catch(error => {
      console.log(error.response);
    });

  }

  async componentDidMount() {
    console.log("RepPage Did Mount id : "+JSON.stringify(this.props.location.state));
    if(this.props.location.state && this.props.location.state.id) {

      let id = this.props.location.state.id;
      let jwtToken = await Promise.resolve(this.currentUserToken());
      let apiName = config.api.API_NAME;
      let path = '/awe_data'; 
      let myInit = { // OPTIONAL
            headers: {
              Authorization: jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL
              id: id
            }
          }
      API.get(apiName, path, myInit).then(response => {
        console.log("Respone : " + JSON.stringify(response));
        this.setState({ rep: response.Items[0].rep });
      }).catch(error => {
        console.log(error.response);
      });

      this.getUsers(jwtToken, this.props.location.state.id);
  
    }


  }

  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {this.state.alert}
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Add/Edit Profile</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="5">
                        <FormGroup className={"has-label " + this.state.rep.company &&  this.state.rep.company !== "" ? "has-success" : "has-danger"}>
                          <label>Company</label>
                          <Input
                            placeholder="Company Name"
                            type="text"
                            id="company"
                            value={this.state.rep.company}
                            onChange={this.onInputChange}
                            
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                      <FormGroup className={"has-label " + this.state.rep.firstName &&  this.state.rep.firstName !== "" ? "has-success" : "has-danger"}>
                          <label>First Name</label>
                          <Input
                            placeholder="Owner First Name"
                            type="text"
                            id="firstName"
                            value={this.state.rep.firstName}
                            onChange={this.onInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="6">
                        <FormGroup className={"has-label " + this.state.rep.lastName &&  this.state.rep.lastName !== "" ? "has-success" : "has-danger"}>
                          <label>Last Name</label>
                          <Input
                            placeholder="Owner Last Name"
                            type="text"
                            id="lastName"
                            value={this.state.rep.lastName}
                            onChange={this.onInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup className={"has-label " + this.state.rep.address &&  this.state.rep.address !== "" ? "has-success" : "has-danger"}>
                          <label>Address</label>
                          <Input
                            placeholder="Office Address"
                            type="text"
                            id="address"
                            value={this.state.rep.address}
                            onChange={this.onInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="4">
                        <FormGroup className={"has-label " + this.state.rep.city &&  this.state.rep.city !== "" ? "has-success" : "has-danger"}>
                          <label>City</label>
                          <Input
                            placeholder="City"
                            type="text"
                            id="city"
                            value={this.state.rep.city}
                            onChange={this.onInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="4">
                        <FormGroup className={"has-label " + this.state.rep.country &&  this.state.rep.country !== "" ? "has-success" : "has-danger"}>
                          <label>Country</label>
                          <Select
                            isDisabled={this.props.disabled}
                            className="primary react-select"
                            classNamePrefix="react-select"
                            placeholder="Country"
                            name="country"
                            id="country"
                            value={this.state.rep.country}
                            options={countryOptions}
                            onChange={value => this.onCountryChange(value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup className={"has-label " + this.state.rep.zip &&  this.state.rep.zip !== "" ? "has-success" : "has-danger"}>
                          <label>Postal Code</label>
                          <Input 
                              placeholder="ZIP Code" 
                              type="text"
                              id="zip"
                              value={this.state.rep.zip}
                              onChange={this.onInputChange}    
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Actions</label>
                          <Button block color="primary" size="lg"
                              
                              disabled={this.state.disabled}
                              onClick={this.handleSubmit}
                              className="mb-3 btn-round"
                          >Update</Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            { this.state.rep.id !== "" && <>
            <Col md="8">
              <Card>
                <CardHeader>
                  <h5 className="title">Add/Edit User</h5>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup className={"has-label " + this.state.user.email &&  this.state.user.email !== "" ? "has-success" : "has-danger"}>
                          <label>Email</label>
                          <Input
                            placeholder="Email Address"
                            type="text"
                            id="email"
                            value={this.state.user.email}
                            onChange={this.onUserInputChange}
                            
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="3">
                      <FormGroup className={"has-label " + this.state.user.firstName &&  this.state.user.firstName !== "" ? "has-success" : "has-danger"}>
                          <label>First Name</label>
                          <Input
                            placeholder="First Name"
                            type="text"
                            id="firstName"
                            value={this.state.user.firstName}
                            onChange={this.onUserInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="3">
                        <FormGroup className={"has-label " + this.state.user.lastName &&  this.state.user.lastName !== "" ? "has-success" : "has-danger"}>
                          <label>Last Name</label>
                          <Input
                            placeholder="Last Name"
                            type="text"
                            id="lastName"
                            value={this.state.user.lastName}
                            onChange={this.onUserInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Actions</label>
                          <Button block color="primary" size="lg"
                              
                              disabled={this.state.userDisabled}
                              onClick={this.handleUserSubmit}
                              className="mb-3 btn-round"
                          >Update</Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Rep Users</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Email</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Status</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.users.map(( listValue, index ) => {
                      console.log(listValue);
                      return (
                        <tr key={index}>
                          <td>{listValue.Attributes.filter(item=>item.Name==="email").map(item=>item.Value)}</td>
                          <td>{listValue.Attributes.filter(item=>item.Name==="given_name").map(item=>item.Value)}</td>
                          <td>{listValue.Attributes.filter(item=>item.Name==="family_name").map(item=>item.Value)}</td>
                          <td>{listValue.UserStatus}</td>
                          <td className="text-right btns-mr-5">
                            <Button
                              className="btn-icon"
                              color="success"
                              id={"tooltipEdit"+index}
                              size="sm"
                              type="button"
                            >
                              <i className="now-ui-icons ui-2_settings-90" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipEdit"+index}
                            >Edit</UncontrolledTooltip>
                            <Button
                              className="btn-icon"
                              disabled={true}
                              color="danger"
                              id={"tooltipRemove"+index}
                              size="sm"
                              type="button"
                            >
                              <i className="now-ui-icons ui-1_simple-remove" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipRemove"+index}
                            >Disable</UncontrolledTooltip>
                          </td>
                        </tr>
                      );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            </>   
            }         
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(RepPage);
