/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";

import countryList from "react-select-country-list";
import Datetime from "react-datetime";
import Select from "react-select";
import { Storage } from 'aws-amplify';

const countryOptions = countryList().getData();

class Interview_2 extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      visitedBefore: "No",
      countriesVisited: [
        {
          countryVisited: "",
          reason: "",
          startDate: "",
          endDate: "",
        },
        {
          countryVisited: "",
          reason: "",
          startDate: "",
          endDate: "",
        },
        {
          countryVisited: "",
          reason: "",
          startDate: "",
          endDate: "",
        }
      ]
    };

    this.valueChange = this.valueChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  

  setProgressValue(pValue) {
    

    console.log("PValue "+this.state.progressValue);
  }

  handleDownload = async event => {
    event.preventDefault();
    console.log("Download File");
    Storage.get(this.state.file.name, {expires: 60})
      .then(result => {
          console.log(result)
          //let blob=new Blob([data.Body], {type: data.ContentType});
          let link=document.createElement('a');
          link.href=result;
          link.download=result;
          link.click();
      
      
      })
      .catch(err => console.log(err));
  }

  valueChange(e) {
    console.log(e.currentTarget.name);
    console.log(e.currentTarget.value);
    this.setState({
      [e.currentTarget.name] : e.currentTarget.value
    });
  }

  

  isValidated() {
    
    return true;
  }

  handleDateChange(field, date) {
    this.setState({ [field]: date.valueOf() });
   }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      this.setState({ ...nextProps });
    }
  }


  render() {
    //console.log("********************* personal info rendering" + JSON.stringify(this.state));
    return (
      <>
        <h5 className="info-text">
          {" "}
          Countries Visited
        </h5>
        <Row className="justify-content-center">
              <Col sm="8">
                <Label sm="6">Has the applicant previously traveled to an English speaking country?</Label>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                  <Input                       
                      defaultValue="Yes"
                      //value={this.state.visitedBefore}
                      name="visitedBefore"
                      onChange={this.valueChange}
                      checked={this.state.visitedBefore === "Yes"}
                      type="radio"/>
                    <span className="form-check-sign" />Yes
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                  <Input                       
                      defaultValue="No"
                      name="visitedBefore"
                      onChange={this.valueChange}
                      checked={this.state.visitedBefore === "No"}
                      type="radio"/>
                    <span className="form-check-sign" />No
                  </Label>
                </FormGroup>
              </Col>
          </Row>
          { this.state.visitedBefore === "Yes" && this.state.countriesVisited.map(( cv, index ) => {
            return (
                <div key={index}>
                  <Row className="justify-content-center">
                    <Col xs={12} sm={2}>
                        <FormGroup>
                          <Label>Country Visited</Label>
                          <Select
                              className="primary react-select"
                              classNamePrefix="react-select"
                              placeholder="Country"
                              name="country"
                              value={this.state.countriesVisited[index].countryVisited}
                              options={countryOptions}
                              onChange={value => {
                                            let state = Object.assign({}, this.state);
                                            state.countriesVisited[index].countryVisited = value;
                                            this.setState(state);
                                          }
                                        }
                          />
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={6}>
                        <FormGroup>
                          <Label>Reason</Label>
                          <Input 
                            value={this.state.countriesVisited[index].reason || ''}
                            type="text"
                            placeholder="Reason for visit"
                            onChange={value => {
                              let state = Object.assign({}, this.state);
                              state.countriesVisited[index].reason = value.target.value;
                              this.setState(state);
                            }
                          }
                          />
                        </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col xs={12} sm={4}>
                        <FormGroup>
                          <Label>Start of Visit Date</Label>
                          <Datetime
                                  dateFormat="YYYY/MM/DD"
                                  value={this.state.interviewDate || ''}
                                  timeFormat={false}
                                  closeOnSelect={true}
                                  inputProps={{ placeholder: "Start Date"  }}
                                  onChange={value => {
                                      let state = Object.assign({}, this.state);
                                      state.countriesVisited[index].startDate = value;
                                      this.setState(state);
                                    }
                                  }
                                />
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm={4}>
                        <FormGroup>
                          <Label>End of Visit Date</Label>
                          <Datetime
                                  dateFormat="YYYY/MM/DD"
                                  value={this.state.interviewDate || ''}
                                  timeFormat={false}
                                  closeOnSelect={true}
                                  inputProps={{ placeholder: "End Date"  }}
                                  onChange={value => {
                                    let state = Object.assign({}, this.state);
                                    state.countriesVisited[index].endDate = value;
                                    this.setState(state);
                                  }
                                }
                              />
                        </FormGroup>
                    </Col>
                  </Row>
                </div>
              );
            })
          }
      </>
    );
  }
}

export default Interview_2;
