/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";

import Switch from "react-bootstrap-switch";

class FamilyBackground extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      economicTies: false,
      economicTiesExplain: "",
      relatives: false,
      relativeInformation: "",
      familyBackground: "",
      travelOutsideReason: "",
      travelOutside: false
    };

    this.valueChange = this.valueChange.bind(this);
  }

  
  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  isValidated() {
    return true;
  }

  handleSwitch(elem, state) {
    this.setState({[elem.props.name]: state });
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    // if (nextProps.firstname !== this.state.firstname) {
    //   this.setState({ firstname: nextProps.firstname });
    // }
    // console.log("EM CReceviing props "+JSON.stringify(nextProps));

    //let state = Object.assign({}, this.state);

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      let newProps = Object.assign({}, nextProps);
      delete newProps.user;
      delete newProps.disabled;
      this.setState({ ...newProps });
    }
  }


  render() {
    return (
      <>
        <h5 className="info-text">
          {" "}
          Family Background
        </h5>
     
        <Row className="justify-content-center">
          <Col xs={12} sm={10}>
            <FormGroup>
               <Label>Can the applicant show strong economic ties to his/her country?</Label>
               <br/>
               <Switch defaultValue={false}
                disabled={this.props.disabled}
                value={this.state.economicTies}
                name="economicTies"
                onChange={(el, state) => this.handleSwitch(el, state)}
                onText="Yes"
                offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
              <Label>Please Explain</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.economicTiesExplain || ''}
                  placeholder="Explain strong economic ties to your country."
                  name="economicTiesExplain"
                  onChange={this.valueChange}
                  type="textarea"
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
               <Label>Does applicant have relatives or friends in the U.S.?</Label>
               <br/>
               <Switch defaultValue={false}
                disabled={this.props.disabled}
                value={this.state.relatives}
                name="relatives"
                onChange={(el, state) => this.handleSwitch(el, state)}
                onText="Yes"
                offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
              <Label>If so, please provide name, address, phone number or email:</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.relativeInformation || ''}
                  placeholder="Relative Information"
                  name="relativeInformation"
                  onChange={this.valueChange}
                  type="textarea"
                  
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
              <Label>Please describe your family background:</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.familyBackground || ''}
                  placeholder="Family Background"
                  name="familyBackground"
                  onChange={this.valueChange}
                  type="textarea"
                  
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
               <Label>Have you traveled outside your country?</Label>
               <br/>
               <Switch defaultValue={false}
                disabled={this.props.disabled}
                value={this.state.travelOutside}
                name="travelOutside"
                onChange={(el, state) => this.handleSwitch(el, state)}
                onText="Yes"
                offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
              <Label>If yes, where have you traveled, the reason for visiting that country and how long you were there</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.travelOutsideReason || ''}
                  placeholder="Your Answer"
                  name="travelOutsideReason"
                  onChange={this.valueChange}
                  type="textarea"
                  
                />
              </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default FamilyBackground;
