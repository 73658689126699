/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Auth, API } from 'aws-amplify';
import config from "../../config";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  CardTitle
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";


class RepListPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };

  }

  handleEdit = async id => {
    console.log("HandleEdit : "+id);
    this.props.history.push({
      pathname: '/admin/rep-page',
      state: { id: id }
    });
  }


  async currentUserToken() {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken.jwtToken;
  }


  async componentDidMount() {

    let jwtToken = await Promise.resolve(this.currentUserToken());
    let apiName = config.api.API_NAME;
    let path = '/awe_data'; 
    let myInit = { // OPTIONAL
          headers: {
            Authorization: jwtToken
          }, // OPTIONAL
          response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
          queryStringParameters: {  // OPTIONAL
            entityName: 'REP'
          }
        }
    API.get(apiName, path, myInit).then(response => {
      console.log("Respone : " + JSON.stringify(response));
      this.setState({ data: response.Items });
    }).catch(error => {
      console.log(error.response);
    });
  }

  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Rep List</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>ID</th>
                        <th>Company Name</th>
                        <th>Owner Name</th>
                        <th>Country</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map(( listValue, index ) => {
                      console.log(listValue);
                      return (
                        <tr key={index}>
                          <td>{listValue.rep && (listValue.rep.id.replace("AWE#REP#", ""))}</td>
                          <td>{listValue.rep && listValue.rep.company}</td>
                          <td>{listValue.rep && listValue.rep.firstName} {listValue.rep && listValue.rep.lastName}</td>
                          <td>{listValue.rep && listValue.rep.country && listValue.rep.country.label}</td>
                          <td className="text-right btns-mr-5">
                            {/* <Button
                              className="btn-icon"
                              color="info"
                              id="tooltip590841497"
                              size="sm"
                              type="button"
                            >
                              <i className="now-ui-icons users_single-02" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip590841497"
                            ></UncontrolledTooltip> */}
                            <Button
                              className="btn-icon"
                              color="success"
                              id={"tooltipEdit"+index}
                              size="sm"
                              type="button"
                              onClick={() => this.handleEdit(listValue.pk)}
                            >
                              <i className="now-ui-icons ui-2_settings-90" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipEdit"+index}
                            >Edit</UncontrolledTooltip>
                            <Button
                              className="btn-icon"
                              disabled={true}
                              color="danger"
                              id={"tooltipRemove"+index}
                              size="sm"
                              type="button"
                            >
                              <i className="now-ui-icons ui-1_simple-remove" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipRemove"+index}
                            >Disable</UncontrolledTooltip>
                          </td>
                        </tr>
                      );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>            
          </Row>
        </div>
      </>
    );
  }
}

export default RepListPage;
