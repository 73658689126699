/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";

import Switch from "react-bootstrap-switch";
import Select from "react-select";

class Education extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      fullTimeStudent: false,
      octFullTimeStudent: false,
      yearOfStudy: "",
      collegeName: "",
      lengthYears: "",
      department: "",
      others: "",
      companyName: "",
      employed: false
    };

    this.valueChange = this.valueChange.bind(this);
  }

  
  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  isValidated() {
    return true;
  }

  handleSwitch(elem, state) {
    this.setState({[elem.props.name]: state });
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    // if (nextProps.firstname !== this.state.firstname) {
    //   this.setState({ firstname: nextProps.firstname });
    // }
    // console.log("EM CReceviing props "+JSON.stringify(nextProps));

    //let state = Object.assign({}, this.state);

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      let newProps = Object.assign({}, nextProps);
      delete newProps.user;
      delete newProps.disabled;
      this.setState({ ...newProps });
    }
  }


  render() {
    return (
      <>
        <h5 className="info-text">
          {" "}
          Education
        </h5>
     
        <Row className="justify-content-center">
          <Col xs={12} sm={5}>
            <FormGroup>
               <Label>Applicant is a full time student?</Label>
               <br/>
               <Switch defaultValue={false}
                disabled={this.props.disabled}
                value={this.state.fullTimeStudent}
                name="fullTimeStudent"
                onChange={(el, state) => this.handleSwitch(el, state)}
                onText="Yes"
                offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
              <label>Applicant will be a full-time student in October of this year?</label>
              <br/>
               <Switch defaultValue={false}
                disabled={this.props.disabled}
                value={this.state.octFullTimeStudent}
                name="octFullTimeStudent"
                onChange={(el, state) => this.handleSwitch(el, state)}               
                onText="Yes"
                offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
              <Label>Year of study</Label>
              <Select
                isDisabled={this.props.disabled}
                className="primary react-select"
                classNamePrefix="react-select"
                placeholder="Choose Year"
                name="yearOfStudy"
                value={this.state.yearOfStudy || ''}
                options={[{ value: "1", label: "1" },{ value: "2", label: "2" },{ value: "3", label: "3" },{ value: "4", label: "4" },{ value: "5", label: "5" },{ value: "6", label: "6" },{ value: "7", label: "7" },{ value: "8", label: "8" }]}
                onChange={value => this.setState({ yearOfStudy: value })}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
              <Label>Name of college or university</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.collegeName || ''}
                  placeholder="Name"
                  name="collegeName"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
              <Label>Length (years) of course/study</Label>
              <Select
                isDisabled={this.props.disabled}
                className="primary react-select"
                classNamePrefix="react-select"
                placeholder="Choose Year"
                name="lengthYears"
                value={this.state.lengthYears || ''}
                options={[{ value: "1", label: "1" },{ value: "2", label: "2" },{ value: "3", label: "3" },{ value: "4", label: "4" },{ value: "5", label: "5" },{ value: "6", label: "6" },{ value: "7", label: "7" },{ value: "8", label: "8" }]}
                onChange={value => this.setState({ lengthYears: value })}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
              <Label>Department</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.department || ''}
                  placeholder="Department"
                  name="department"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
              <Label>Others</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.others || ''}
                  placeholder="Others"
                  name="others"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
        </Row>
        <h5 className="info-text">
          {" "}
          Employment
        </h5>
     
        <Row className="justify-content-center">
        <Col xs={12} sm={10}>
            <FormGroup>
               <Label>Applicant is employed in his/her home country?</Label>
               <br/>
               <Switch defaultValue={false}
                  disabled={this.props.disabled}
                  value={this.state.employed}
                  name="employed"
                  onChange={(el, state) => this.handleSwitch(el, state)}               
                  onText="Yes"
                  offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
              <Label>Name of company where you plan to work in the U.S.</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.companyName || ''}
                  placeholder="Company Name"
                  name="companyName"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default Education;
