/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";

import { Storage } from 'aws-amplify';



class Interview_4 extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      appearance: {},
      personality: {}
    };

    this.valueChange = this.valueChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  

  setProgressValue(pValue) {
    

    console.log("PValue "+this.state.progressValue);
  }

  handleDownload = async event => {
    event.preventDefault();
    console.log("Download File");
    Storage.get(this.state.file.name, {expires: 60})
      .then(result => {
          console.log(result)
          //let blob=new Blob([data.Body], {type: data.ContentType});
          let link=document.createElement('a');
          link.href=result;
          link.download=result;
          link.click();
      
      
      })
      .catch(err => console.log(err));
  }

  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  handleAppearance(e, type) {
    let val = this.state.appearance[type] === "Yes" ? "No" : "Yes";
    let state = Object.assign({}, this.state);
    state.appearance[type] = val;
    this.setState(state);
  }

  

  isValidated() {
    
    return true;
  }

  handleDateChange(field, date) {
    this.setState({ [field]: date.valueOf() });
   }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      this.setState({ ...nextProps });
    }
  }


  render() {
    //console.log("********************* personal info rendering" + JSON.stringify(this.state));
    return (
      <>
        <h5 className="info-text">
          {" "}
          Review
        </h5>
        <Row>
          <Col sm="10">
            <Label>ADDITIONAL INFORMATION IN SUPPORT OF THIS APPLICANT:</Label>
          </Col>        
        </Row>
        <Row className="justify-content-center">        
            <Col sm="10" >
              <FormGroup>
                <Label>PLEASE, WRITE AS MUCH AS POSSIBLE TO THIS SECTION ABOUT THE APPLICANT.</Label>
                <Input
                  value={this.state.additionalInfo || ''}
                  placeholder="Additional Information"
                  name="additionalInfo"
                  onChange={this.valueChange}
                  type="textarea"
                  style={{maxHeight: 300+'px'}}
                  rows="15"
                  
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
              <Col sm="10">
                <Label>Are you satisfied that the following sections on the application form have been completed honestly?</Label>
                <br/>
                <Label sm="5">Medical information?</Label>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input
                      defaultValue="option1"
                      id="exampleRadios1"
                      name="medicalInfo"
                      type="radio"/>
                    <span className="form-check-sign" />Yes
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="option1"
                      id="exampleRadios1"
                      name="medicalInfo"
                      type="radio"/>
                    <span className="form-check-sign" />No
                  </Label>
                </FormGroup>
              </Col>
              <Col sm="10">
                <Label sm="5">Confidential background check?</Label>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input
                      defaultValue="option1"
                      id="exampleRadios1"
                      name="backgroundCheck"
                      type="radio"/>
                    <span className="form-check-sign" />Yes
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="option1"
                      id="exampleRadios1"
                      name="backgroundCheck"
                      type="radio"/>
                    <span className="form-check-sign" />No
                  </Label>
                </FormGroup>
              </Col>
              <Col sm="10">
                <Label sm="5">Does applicant understand and have a copy of the Terms and Conditions?</Label>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input
                      defaultValue="option1"
                      id="exampleRadios1"
                      name="termsAndConditions"
                      type="radio"/>
                    <span className="form-check-sign" />Yes
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="option1"
                      id="exampleRadios1"
                      name="termsAndConditions"
                      type="radio"/>
                    <span className="form-check-sign" />No
                  </Label>
                </FormGroup>
              </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} sm={10}>
                <FormGroup>
                  <Label>Interviewers Name</Label>
                  <Input 
                    value={this.state.interviewersName || ''}
                    name="interviewersName"
                    type="text"
                    placeholder="Name"
                    onChange={this.valueChange}
                  />
                </FormGroup>
            </Col>
          </Row>
      </>
    );
  }
}

export default Interview_4;
