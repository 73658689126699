/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "components/ReactWizard";
import { withRouter } from 'react-router-dom';

// reactstrap components
import { Col } from "reactstrap";

import { Auth, API } from 'aws-amplify';
import config from "../../../config";


// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import Interview_1 from "./Interview_1.jsx";
import Interview_2 from "./Interview_2.jsx";
import Interview_3 from "./Interview_3.jsx";
import Interview_4 from "./Interview_4.jsx";


class Wizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
        id: null,
        steps : [
          {
            stepName: "Step 1",
            stepIcon: "now-ui-icons users_circle-08",
            component: Interview_1
          },
          {
            stepName: "Step 2",
            stepIcon: "now-ui-icons users_circle-08",
            component: Interview_2
          },
          {
            stepName: "Step 3",
            stepIcon: "now-ui-icons users_circle-08",
            component: Interview_3
          },
          {
            stepName: "Step 4",
            stepIcon: "now-ui-icons users_circle-08",
            component: Interview_4
          }
        ]
    };
    this.getId = this.getId.bind(this);
    this.setId = this.setId.bind(this);
    this.updateSteps = this.updateSteps.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
  }

  async finishButtonClick(allStates, setId, getId, butType, updateSteps) {
    console.log("ID: "+getId());
    // console.log("ALL States "+JSON.stringify(allStates));
    
    const user = await Auth.currentAuthenticatedUser();

    
    //remove the wizard data from all
    // if(allStates["Emergency Contact"].wizardData) {
    //   delete allStates["Emergency Contact"].wizardData;
    // }

    // [
    //   "Step 1", 
    //   "Step 2", 
    //   "Step 3",
    //   "Step 4"
    // ].forEach(function(key) {
    //   if (allStates[key] && allStates[key].wizardData) {
    //     delete allStates[key].wizardData;
    //   }
    // });
    
   

    //set the id
    allStates.id = getId();

    // let steps = Object.assign([], this.state.steps);
    //     steps.forEach(function (item, index) {
    //       //console.log(item, index);
    //       item.stepProps = dbSteps[item.stepName];
    //     });
    // setId("steps", allStates);

    let jwtToken = user.signInUserSession.idToken.jwtToken;
    // console.log("user "+JSON.stringify(user));

    // console.log(this.props.auth.user);
    // user.attributes["custom:userType"]
    let apiName = config.api.API_NAME; 
    let path = "/awe_update"; 
    let myInit = {
      body: {
          pk: "APP",
          sk: "APP",
          itemType: "interviewersReport",
          //data: new Date().getTime()+"",
          data: user.attributes["custom:userType"],
          item: allStates
      }, 
      headers: {
        Authorization: jwtToken
      }
    }

    API.post(apiName, path, myInit).then(response => {
      console.log("Success id : "+ response.id);
      //this.notifcationAlert(true, "Updated Successfully", "Saved");
      // let rep = this.state.rep;
      // rep.id = response.id;
      setId(response.id);
      //updateSteps(response.record);
      if(butType === "FINISH") {
        this.props.history.push("/rep");
      }
    }).catch(error => {
      console.log(error)
    });

  }

  setId(id) {
    console.log("Set Id = "+id);
    this.setState({
      id: id
    })
  }

  getId() {
    return this.state.id;
  }

  updateSteps(dbSteps) {
    //console.log("DbSteps "+JSON.stringify(dbSteps));
    if(dbSteps) {
      let steps = Object.assign([], this.state.steps);
          steps.forEach(function (item, index) {
            //console.log(item, index);
            item.stepProps = dbSteps[item.stepName];
      });
      this.setState({ steps: steps });
    }
  }
  
  async componentWillMount() {
    //console.log("Wizard Did Mount id : "+JSON.stringify(this.props.location.state));
    if(this.props.location.state && this.props.location.state.id) {

      let id = this.props.location.state.id;
      this.setId(id);
      const user = await Auth.currentAuthenticatedUser();
      let jwtToken = user.signInUserSession.idToken.jwtToken;
      let apiName = config.api.API_NAME;
      let path = '/awe_data'; 
      let myInit = { // OPTIONAL
            headers: {
              Authorization: jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL
              id: id
            }
          }
      API.get(apiName, path, myInit).then(response => {
        console.log("Respone : " + JSON.stringify(response));

        let dbSteps = response.Items[0].interviewersReport;
        this.updateSteps(dbSteps);
      }).catch(error => {
        console.log(error.response ? error.response : error);
      });
    }
  }



  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          <Col xs={12} md={10} className="mr-auto ml-auto">
            <ReactWizard
              steps={this.state.steps}
              //progressbar
              navSteps
              //validate
              title="Applicant Interview"
              description="This information will let us know more about the applicant."
              headerTextCenter
              color="blue"
              finishButtonClasses="btn-wd"
              nextButtonClasses="btn-wd"
              previousButtonClasses="btn-wd"
              finishButtonClick={this.finishButtonClick}
              nextButtonClick={this.finishButtonClick}
              setId={this.setId}
              getId={this.getId}
              updateSteps={this.updateSteps}
            />
          </Col>
        </div>
      </>
    );
  }
}

export default withRouter(Wizard);
