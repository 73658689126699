/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Auth, API } from 'aws-amplify';
import config from "../../config";
import { withRouter } from 'react-router-dom';


// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import AppList from "components/AppList.jsx";


class AppListPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: []
    };

  }

  handleEdit = id => {
    console.log("HandleEdit : "+id);
    this.props.history.push({
      pathname: '/rep/wizard',
      state: { id: id }
    });
  }

  handleInterviewNotes = id => {
    console.log("handleInterviewNotes : "+id);
    this.props.history.push({
      pathname: '/rep/interview/wizard',
      state: { id: id }
    });
  }


  async currentUserToken() {
    const user = await Auth.currentAuthenticatedUser();
    return user.signInUserSession.idToken.jwtToken;
  }


  async componentDidMount() {
    
    let jwtToken = await Promise.resolve(this.currentUserToken());
    let apiName = config.api.API_NAME;
    let path = '/awe_app'; 
    let myInit = { // OPTIONAL
          headers: {
            Authorization: jwtToken
          }, // OPTIONAL
          response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
          queryStringParameters: {  // OPTIONAL
            entityName: 'APP'
          }
        }
    API.get(apiName, path, myInit).then(response => {
      //console.log("Respone : " + JSON.stringify(response));
      this.setState({ data: response.Items });
    }).catch(error => {
      console.log(error.response);
    });
  }

  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <AppList/>
      </>
    );
  }
}

export default withRouter(AppListPage);
