/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Auth, API } from 'aws-amplify';
import config from "../config";
import { withRouter } from 'react-router-dom';
import SweetAlert from "react-bootstrap-sweetalert";

import findUserLayout from "../util/UserUtil";


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  UncontrolledTooltip,
  CardTitle
} from "reactstrap";



class AppList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alert: null
    };

  }

  handleEdit = id => {
    console.log("HandleEdit : "+id);
    let disabled = false;
    let pathname = findUserLayout(this.state.user) + '/wizard';
    if(this.state.user.attributes["custom:userType"].indexOf("ADMIN") !== -1){
      disabled = true;
    }

    this.props.history.push({
      pathname: pathname,
      state: { 
        id: id,
        disabled: disabled   
      }
    });
  }

  handleInterviewNotes = id => {
    console.log("handleInterviewNotes : "+id);
    this.props.history.push({
      pathname: '/rep/interview/wizard',
      state: { id: id }
    });
  }


  handleLockApplication = (listValue, index) => {
    console.log("handleLockApplication : "+listValue.application.id +" Status : "+listValue.application.status +" index : "+index);
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Lock Application?"
          onConfirm={() => this.successLock(listValue)}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, lock it!"
          cancelBtnText="Cancel"
          showCancel
        >
          You will not be able to unlock this application after.
        </SweetAlert>
      )
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  successLock(listValue) {
    let myInit = {
      body: {
          sk: "APP",
          id: listValue.application.id,
          status: "Locked"
        }, 
      headers: {
        Authorization: this.state.user.signInUserSession.idToken.jwtToken
      }
    }
    

    API.post(config.api.API_NAME, "/awe_update_application_status", myInit).then(response => {
      console.log("Success id : "+ response.id);
      listValue.application.status = "Locked";
      this.setState({
        alert: (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => this.hideAlert()}
            onCancel={() => this.hideAlert()}
            confirmBtnBsStyle="info"
          >
            The application has been locked.
          </SweetAlert>
        )
      });
  
    }).catch(error => {
      console.log(error)
    });


  }


  


  async componentDidMount() {
    console.log("AppList componentWillMount ");

    const user = await Auth.currentAuthenticatedUser();
    let jwtToken = user.signInUserSession.idToken.jwtToken;
    this.setState({ user : user});
    
    let apiName = config.api.API_NAME;
    let path = '/awe_app'; 
    let myInit = { // OPTIONAL
          headers: {
            Authorization: jwtToken
          }, // OPTIONAL
          response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
          queryStringParameters: {  // OPTIONAL
            entityName: 'APP'
          }
        }
    API.get(apiName, path, myInit).then(response => {
      console.log("Respone : " + JSON.stringify(response));
      this.setState({ data: response.Items });
    }).catch(error => {
      console.log(error.response);
    });
  }

  render() {
    return (
      <>
        <div className="content">
        {this.state.alert}
          <Row>
            <Col md="8">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Applicant List</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Applicant ID</th>
                        <th>Applicant Name</th>
                        <th>Status</th>
                        <th className="text-right">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map(( listValue, index ) => {
                      // console.log(listValue);
                     
                      const personalInfo = 'Personal Info.';
                      let firstName = "";
                      let lastName = "";
                      if(listValue.application[personalInfo]) {
                        firstName = listValue.application[personalInfo].firstName;
                        lastName = listValue.application[personalInfo].lastName;
                      }

                      let status = listValue.application.status;

                      return (
                        
                        <tr key={index}>
                          <td>{listValue.application.id.replace("AWE#APP#", "")}</td>
                          <td>{firstName} {lastName}</td>
                          <td>{status}</td>
                          <td className="text-right btns-mr-5">
                            {/* <Button
                              className="btn-icon"
                              color="info"
                              id="tooltip590841497"
                              size="sm"
                              type="button"
                              onClick={() => this.handleInterviewNotes(listValue.pk)}
                            >
                              <i className="now-ui-icons users_single-02" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip590841497"
                            >Add Interview Notes</UncontrolledTooltip> */}
                            { this.state.user.attributes["custom:userType"].indexOf("ADMIN") !== -1 && status !== "Locked" &&
                               <>
                                  <Button
                                      className="btn-icon"
                                      color="info"
                                      id={"tooltipLock"+index}
                                      size="sm"
                                      type="button"
                                      onClick={() => this.handleLockApplication(listValue, index)}
                                  >
                                  <i className="now-ui-icons ui-1_lock-circle-open" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target={"tooltipLock"+index}
                                  >Lock Application</UncontrolledTooltip>
                              </>
                            }
                            <Button
                              className="btn-icon"
                              color="success"
                              id={"tooltipEdit"+index}
                              size="sm"
                              type="button"
                              onClick={() => this.handleEdit(listValue.application.id)}
                            >
                              <i className="now-ui-icons ui-2_settings-90" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltipEdit"+index}
                            >View/Edit</UncontrolledTooltip>
                            {/* <Button
                              className="btn-icon"
                              color="danger"
                              id="tooltip930083782"
                              size="sm"
                              type="button"
                            >
                              <i className="now-ui-icons ui-1_simple-remove" />
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip930083782"
                            >Disable</UncontrolledTooltip> */}
                          </td>
                        </tr>
                      );
                      
                      })}
                    
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>            
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(AppList);
