/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
  Progress,
  FormGroup,
  Label
} from "reactstrap";

import countryList from "react-select-country-list";
import Datetime from "react-datetime";
import Select from "react-select";
import { Storage } from 'aws-amplify';
import uuid from "uuid";

// core components
import PictureUpload from "components/CustomUpload/PictureUpload.jsx";

const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const countryOptions = countryList().getData();

class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      firstName: "",
      lastName: "",
      middelName: "",
      email: "",
      file: null,
      progressValue: 0,
      mobile: "",
      startDate: "",
      endDate: "",
      program: "",
      gender: "",
      applicantType: "",
      streetAddress: "",
      townCity: "",
      postCode: "",
      country: "",
      countryOfCitizenship: "",
      cityOfBirth: "",
      countryOfBirth: "",
      dateOfBirth: ""      
    };

    this.valueChange = this.valueChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  

  setProgressValue(pValue) {
    

    console.log("PValue "+this.state.progressValue);
  }

  handleDownload = async event => {
    event.preventDefault();
    console.log("Download File");
    const customPrefix = {
      public: 'AWE#APP#profilePics/'
    };
    Storage.get(this.state.s3FileName, 
      {
       expires: 60,
       customPrefix: customPrefix
      })
      .then(result => {
          console.log(result)
          //let blob=new Blob([data.Body], {type: data.ContentType});
          let link=document.createElement('a');
          link.href=result;
          link.download=result;
          link.click();
      
      
      })
      .catch(err => console.log(err));
  }

  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }


  
  saveFile =  (file) => {

    const customPrefix = {
      public: 'AWE#APP#profilePics/'
    };

    console.log("Save File");
    const parentThis = this;
    let fileName = uuid.v4();
    Storage.put(fileName, file, {
      level: 'public',   //default
      customPrefix: customPrefix,
      contentDisposition: "attachment; filename="+file.name+"",
      progressCallback(progress) {
        let progressValue = Math.round(progress.loaded / progress.total * 100);
        parentThis.setState({
          progressValue: progressValue
        });

      }
    } )
    .then (result => {
      console.log("Save File Result : "+JSON.stringify(result)) // {key: "test.txt"};
      this.setState({        
        s3FileName: fileName
      });
    })
    .catch(err => {
      console.log(err)
    })
  
    
  }

  isValidated() {
    let state = this.state;

    

    if(state.firstName === "" || 
       state.lastName === "" || 
       !emailRex.test(state.email) || 
       state.mobile === ""){
      return false;
    }
    
    return true;
  }

  handleDateChange(field, date) {
    this.setState({ [field]: date.valueOf() });
   }

   componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      let newProps = Object.assign({}, nextProps);
      delete newProps.user;
      delete newProps.disabled;
      this.setState({ ...newProps });
    }

    if(nextProps.user.attributes && nextProps.user.attributes["custom:userType"].endsWith("#APPLICANT")) {
        this.setState({
            email: nextProps.user.attributes.email,
            userType: nextProps.user.attributes["custom:userType"]
        });
        if(this.state.firstName === "") {
          this.setState({firstName: nextProps.user.attributes.given_name});
        }
        if(this.state.lastName === "") {
          this.setState({lastName: nextProps.user.attributes.family_name});
        }

    }

  }

  

  render() {
    //console.log("********************* personal info rendering" + JSON.stringify(this.state));
    return (
      <>
        <h5 className="info-text">
          {" "}
          Let's start with your application
        </h5>
        <Row className="justify-content-center">
          <Col xs={12} sm="4">
            <PictureUpload disabled={this.props.disabled} saveFile={this.saveFile} />
            { this.state.progressValue > 0 &&
              <div className="progress-container progress-primary">
                          <span className="progress-badge">Upload Progress</span>
                        <Progress max="100" value={this.state.progressValue}>
                          <span className="progress-value">{this.state.progressValue}%</span>
                        </Progress>
                      </div>

            }
            { (this.state.s3FileName && this.state.s3FileName !== "") &&
            <div className="btns-mr-5">
            <Button block color="primary"
                              
                              
                              onClick={this.handleDownload}
                              size="sm"
                          >Download</Button>
                          </div>
            }

          </Col>
          <Col xs={12} sm="6">
            <InputGroup
              className={"form-control-lg " + (this.state.firstName !== '' ? "has-success" : "has-danger")}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="now-ui-icons users_circle-08" />
                </InputGroupText>
              </InputGroupAddon>
              <Input   
                disabled={this.props.disabled}
                placeholder="First Name"
                name="firstName"
                value={this.state.firstName || ''}
                onChange={this.valueChange}
              />
            </InputGroup>
            <InputGroup className={"form-control-lg"}>
            <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="now-ui-icons users_circle-08" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                disabled={this.props.disabled}
                value={this.state.middleName || ''}
                type="text"
                placeholder="Middle Name"
                name="middleName"
                onChange={this.valueChange}
              />
            </InputGroup>
            <InputGroup
              className={"form-control-lg " + (this.state.lastName !== '' ? "has-success" : "has-danger")}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="now-ui-icons text_caps-small" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                disabled={this.props.disabled}
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={this.state.lastName || ''}
                onChange={this.valueChange}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} sm={5}>
            <FormGroup className={"has-label " + (emailRex.test(this.state.email) ? "has-success" : "has-danger" )}>
              <Label>Email</Label>
              <Input
                  disabled={this.props.disabled || 
                    (this.props.user.attributes && this.props.user.attributes["custom:userType"].endsWith("#APPLICANT")) || 
                    (this.state.userType && this.state.userType.endsWith("#APPLICANT"))
                  }
                  value={this.state.email || ''}
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={this.valueChange}
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup className={"has-label " + (this.state.mobile !== "" ? "has-success" : "has-danger") }>
              <Label>Mobile</Label>
              <Input 
                disabled={this.props.disabled}
                value={this.state.mobile || ''}
                name="mobile"
                type="text"
                placeholder="mobile"
                onChange={this.valueChange}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
              <Label>Applicant confirmed the date available to start work</Label>
              <Datetime
                      value={this.state.startDate || ''}
                      timeFormat={false}
                      closeOnSelect={true}
                      dateFormat="YYYY/MM/DD"
                      inputProps={{ placeholder: "Start Date", disabled:this.props.disabled }}
                      onChange={(e) => this.handleDateChange("startDate", e)}
                    />
            </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
              <Label>Applicant confirmed the date available to end work:</Label>
              <Datetime
                      value={this.state.endDate || ''}
                      timeFormat={false}
                      dateFormat="YYYY/MM/DD"
                      closeOnSelect={true}
                      inputProps={{ placeholder: "End Date", disabled:this.props.disabled }}
                      onChange={(e) => this.handleDateChange("endDate", e)}
                    />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4}>
            <FormGroup>
              <Label>Program applying for</Label>
              <Select
                isDisabled={this.props.disabled}
                className="primary react-select"
                classNamePrefix="react-select"
                placeholder="Program"
                name="program"
                value={this.state.program}
                options={[{ value: "Self Arranged", label: "Self Arranged" },{ value: "Placement Option", label: "Placement Option" }]}
                onChange={value => this.setState({ program: value })}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={3}>
            <Label>Gender</Label>
            <Select
                isDisabled={this.props.disabled}
                className="primary react-select"
                classNamePrefix="react-select"
                placeholder="Gender"
                name="gender"
                value={this.state.gender}
                options={[{ value: "M", label: "Male" },{ value: "F", label: "Female" }]}
                onChange={value => this.setState({ gender: value })}
              />
          </Col>
          <Col xs={12} sm={3}>
            <Label>Applicant Type</Label>
            <Select
                isDisabled={this.props.disabled}
                className="primary react-select"
                classNamePrefix="react-select"
                placeholder="Type"
                name="applicantType"
                value={this.state.applicantType}
                options={[{ value: "New", label: "New" },{ value: "Returning", label: "Returning" },{ value: "AWE Returning", label: "AWE Returning" }]}
                onChange={value => this.setState({ applicantType: value })}
              />
          </Col>

          <Col xs={12} sm={7}>
            <FormGroup>
              <Label>Street address (including apartment number if any)</Label>
              <Input 
                  disabled={this.props.disabled}
                  value={this.state.streetAddress || ''}
                  type="text"
                  placeholder="Street Address"
                  name="streetAddress"
                  onChange={this.valueChange} 
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={3}>
            <FormGroup>
              <Label>Town/City</Label>
              <Input 
                  disabled={this.props.disabled}
                  value={this.state.townCity || ''}
                  type="text"
                  placeholder="Town/City"
                  name="townCity"
                  onChange={this.valueChange} 
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4}>
            <FormGroup>
              <Label>Post/City Code</Label>
              <Input 
                  disabled={this.props.disabled}
                  value={this.state.postCode || ''}
                  type="text"
                  placeholder="Post/City Code"
                  name="postCode"
                  onChange={this.valueChange} 
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={3}>
            <Label>Country of Legal Permanent Resident</Label>
            <Select
              isDisabled={this.props.disabled}
              className="primary react-select"
              classNamePrefix="react-select"
              placeholder="Country"
              name="country"
              value={this.state.country}
              options={countryOptions}
              onChange={value => this.setState({ country: value })}
            />
          </Col>
          <Col xs={12} sm={3}>
            <FormGroup>
              <Label>Country of Citizenship</Label>
                <Select
                  isDisabled={this.props.disabled}
                  className="primary react-select"
                  classNamePrefix="react-select"
                  placeholder="Country of Citizenship"
                  name="countryOfCitizenship"
                  value={this.state.countryOfCitizenship}
                  options={countryOptions}
                  onChange={value => this.setState({ countryOfCitizenship: value })}
                />
            </FormGroup>
          </Col>
          <Col xs={12} sm={3}>
            <FormGroup>
              <Label>Date of Birth</Label>
              <Datetime
                      value={this.state.dateOfBirth || ''}
                      timeFormat={false}
                      dateFormat="YYYY/MM/DD"
                      closeOnSelect={true}
                      inputProps={{ placeholder: "Date of Birth", disabled:this.props.disabled }}
                      onChange={(e) => this.handleDateChange("dateOfBirth", e)}
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={4}>
            <FormGroup>
              <Label>City of Birth</Label>
              <Input 
                  disabled={this.props.disabled}
                  value={this.state.cityOfBirth || ''}
                  type="text"
                  placeholder="City of Birth"
                  name="cityOfBirth"
                  onChange={this.valueChange}               
              />
            </FormGroup>
          </Col>
          <Col xs={12} sm={3}>
            <FormGroup>
              <Label>Country of Birth</Label>
                <Select
                  isDisabled={this.props.disabled}
                  className="primary react-select"
                  classNamePrefix="react-select"
                  placeholder="Country of Birth"
                  name="countryOfBirth"
                  value={this.state.countryOfBirth}
                  options={countryOptions}
                  onChange={value => this.setState({ countryOfBirth: value })}
                />
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default PersonalInfo;
