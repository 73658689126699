/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
// react plugin used to create a form with multiple steps
import ReactWizard from "components/ReactWizard";
import { withRouter } from 'react-router-dom';

// reactstrap components
import { Col } from "reactstrap";

import { Auth, API } from 'aws-amplify';
import config from "../../../config";
import SweetAlert from "react-bootstrap-sweetalert";


// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import PersonalInfo from "./PersonalInfo.jsx";
import EmergencyContact from "./EmergencyContact.jsx";
import Education from "./Education.jsx";
import Skills from "./Skills.jsx";
import FamilyBackground from "./FamilyBackground.jsx";
import MedicalInformation from "./MedicalInformation.jsx";
import BackgroundCheck from "./BackgroundCheck.jsx";
import ParticipantAgreement from "./ParticipantAgreement.jsx"

import findUserLayout from "../../../util/UserUtil";

class Wizard extends Component {
  constructor(props) {
    super(props);

    this.state = {
        id: null,
        alert: null,
        disabled: true,
        user: {},
        steps : [
          {
            stepName: "Personal Info.",
            stepIcon: "now-ui-icons users_circle-08",
            component: PersonalInfo
          },
          {
            stepName: "Emergency Contact",
            stepIcon: "now-ui-icons users_circle-08",
            component: EmergencyContact
          },
          {
            stepName: "Education / Employment",
            stepIcon: "now-ui-icons ui-1_settings-gear-63",
            component: Education
          },
          {
            stepName: "Skills",
            stepIcon: "now-ui-icons ui-1_check",
            component: Skills
          },
          {
            stepName: "Family Background",
            stepIcon: "now-ui-icons ui-2_chat-round",
            component: FamilyBackground
          },
          {
            stepName: "Medical Information",
            stepIcon: "now-ui-icons ui-1_bell-53",
            component: MedicalInformation
          },
          {
            stepName: "Background Check",
            stepIcon: "now-ui-icons ui-1_lock-circle-open",
            component: BackgroundCheck
          },
          {
            stepName: "Participant Agreement",
            stepIcon: "now-ui-icons ui-1_send",
            component: ParticipantAgreement
          }

        ]
    };
    this.getId = this.getId.bind(this);
    this.setId = this.setId.bind(this);
    this.updateSteps = this.updateSteps.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.submitCancelled = this.submitCancelled.bind(this);
    this.lockedCancelled = this.lockedCancelled.bind(this);
  }

  async finishButtonClick(allStates, setId, getId, butType, updateSteps) {
    const user = await Auth.currentAuthenticatedUser();
    console.log("ID: "+getId() +" Disabled :" +this.state.disabled +" Status : "+this.state.status+" ButType: "+butType+" UserType: "+user.attributes["custom:userType"]);
    //console.log("ALL States "+JSON.stringify(allStates));

    let admin = user.attributes["custom:userType"].indexOf("ADMIN") !== -1;
    let applicant = user.attributes["custom:userType"].indexOf("APPLICANT") !== -1;
    let rep = user.attributes["custom:userType"].indexOf("REP") !== -1 && user.attributes["custom:userType"].indexOf("APPLICANT") === -1;
    // let layout = admin ? "/admin" : rep ? "/rep" : "/appl";
    let layout = findUserLayout(user);

    console.log("Rep : "+rep +" Applicant : "+applicant + " Admin : "+admin);

    if(this.state.disabled === true) {
      
      

      if(butType === "FINISH" ) {
        if(admin && this.state.status === 'Submitted'){
          console.log("handleLockApplication : "+getId());
          this.setState({
            alert: (
              <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Lock Application?"
                onConfirm={() => this.successLock(getId(), user, layout, "Locked")}
                onCancel={() => this.lockedCancelled(layout, user, getId())}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, lock it!"
                cancelBtnText="Cancel"
                showCancel
              >
                You will not be able to unlock this application after.
              </SweetAlert>
            )
          });
        } else if(rep && this.state.status === 'Ready'){
          console.log("handleLockApplication : "+getId());
          this.setState({
            alert: (
              <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Submit Application?"
                onConfirm={() => this.successLock(getId(), user, layout, "Submitted")}
                onCancel={() => this.submitCancelled(layout, user, getId())}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, Submit it!"
                cancelBtnText="Cancel"
                showCancel
              >
                You will not be able to edit this application after.
              </SweetAlert>
            )
          });
        } else {
          this.props.history.push(layout);
        }
      }
      return;
    }
    
    

    
    //remove the wizard data from all
    // if(allStates["Emergency Contact"].wizardData) {
    //   delete allStates["Emergency Contact"].wizardData;
    // }

    // [
    //   "Personal Info.", 
    //   "Emergency Contact", 
    //   "Education / Employment", 
    //   "Skills",
    //   "Family Background",
    //   "Medical Information",
    //   "Background Check",
    //   "Participant Agreement"
    // ].forEach(function(key) {
    //   if (allStates[key] && allStates[key].wizardData) {
    //     delete allStates[key].wizardData;
    //   }
    // });
    
   

    //set the id
    allStates.id = getId();

    // let steps = Object.assign([], this.state.steps);
    //     steps.forEach(function (item, index) {
    //       //console.log(item, index);
    //       item.stepProps = dbSteps[item.stepName];
    //     });
    // setId("steps", allStates);

    let jwtToken = user.signInUserSession.idToken.jwtToken;
    // console.log("user "+JSON.stringify(user));

    // console.log(this.props.auth.user);
    // user.attributes["custom:userType"]

    let apiName = config.api.API_NAME; 

    let path = "/awe_data"; 
    let myInit;
    if(allStates.id) {
      path = "/awe_update"
    } 
    let status = "Draft";
    if(butType === "FINISH") {
      //If its a rep the status is submitted if its an applicant the status is ready.
      status = rep ? "Submitted" : "Ready";
    }

    allStates.status = status;
    let data = user.attributes["custom:userType"];
    data = data.replace("#APPLICANT", "");
 console.log(JSON.stringify(allStates));
    myInit = {
      body: {
          pk: "APP",
          sk: "APP",
          //data: new Date().getTime()+"",
          data: data,
          itemType: "application",
          application: allStates 
      }, 
      headers: {
        Authorization: jwtToken
      }
    }

    //this is an not an update
    if(!allStates.id) {
      myInit.body.createdByType = user.attributes["custom:userType"];
      myInit.body.createdByTypeEmail = user.attributes.email;
    }
    

    API.post(apiName, path, myInit).then(response => {
      console.log("Success id : "+ response.id);
      //this.notifcationAlert(true, "Updated Successfully", "Saved");
      // let rep = this.state.rep;
      // rep.id = response.id;
      setId(response.id);
      updateSteps(response.record);
      if(butType === "FINISH") {
        this.props.history.push(layout);
      }
    }).catch(error => {
      console.log(error)
    });

  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  lockedCancelled(layout, user, id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Reject Application?"
          onConfirm={() => this.successLock(id, user, layout, "Ready")}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Reject it!"
          cancelBtnText="Cancel"
          showCancel
        >
          Allow the rep to review the application?
        </SweetAlert>
      )
    });
  }


  submitCancelled(layout, user, id) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Reject Application?"
          onConfirm={() => this.successLock(id, user, layout, "Draft")}
          onCancel={() => this.hideAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Reject it!"
          cancelBtnText="Cancel"
          showCancel
        >
          Allow the applicant edit and resubmit the application?
        </SweetAlert>
      )
    });
  }

  successLock(id, user, layout, status) {
    let myInit = {
      body: {
          sk: "APP",
          id: id,
          status: status
        }, 
      headers: {
        Authorization: user.signInUserSession.idToken.jwtToken
      }
    }
    

    API.post(config.api.API_NAME, "/awe_update_application_status", myInit).then(response => {
      console.log("Success id : "+ response.id);
      this.setState({
        alert: (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title= "Success!"
            onConfirm={() => {
              this.hideAlert();
              this.props.history.push(layout);
            }}
            onCancel={() => {
              this.hideAlert();
              this.props.history.push(layout);
            }}
            confirmBtnBsStyle="info"
          >
            The application status has been changed to {status+"!"}
          </SweetAlert>
        )
      });
  
    }).catch(error => {
      console.log(error)
    });


  }

  setId(id) {
    console.log("Set Id = "+id);
    this.setState({
      id: id
    })
  }

  getId() {
    return this.state.id;
  }

  updateSteps(dbSteps) {
    //console.log("DbSteps "+JSON.stringify(dbSteps));
    let steps = Object.assign([], this.state.steps);
        steps.forEach(function (item, index) {
          //console.log(item, index);
          item.stepProps = dbSteps[item.stepName];
    });
    this.setState({ steps: steps });
  }
  
  // componentWillMount() is invoked immediately before mounting occurs. It is called before render(), therefore setting state synchronously in this method will not trigger a re-rendering. 
  // Avoid introducing any side-effects or subscriptions in this method. This is the only lifecycle hook called on server rendering. Generally, we recommend using the constructor() instead.
  async componentWillMount() {
    const user = await Auth.currentAuthenticatedUser();
    let jwtToken = user.signInUserSession.idToken.jwtToken;
    let apiName = config.api.API_NAME;
    let path = '/awe_app'; 

    let applicant = user.attributes["custom:userType"].indexOf("APPLICANT") !== -1;
    let applicantAppId = "NA";
    //if is an applicant we need to find there last application
    if(applicant) {
      //go get there last one if they have one.
      let myInit = { // OPTIONAL
        headers: {
          Authorization: jwtToken
        }, // OPTIONAL
        response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      }
      await API.get(apiName, path, myInit).then(response => {
        console.log("Respone : " + JSON.stringify(response));
        let size = response.Items.length;
        if(size > 0) {
          applicantAppId = response.Items[size-1].application.id;
        }
        console.log("applicantAppId "+ applicantAppId);
      }).catch(error => {
        console.log(error.response ? error.response : error);
      });
    }



    //console.log("Wizard Did Mount id : "+JSON.stringify(this.props.location.state));
    if((this.props.location.state && this.props.location.state.id) || applicantAppId !== "NA") {
      let id;
      if(applicantAppId !== "NA") {
        id = applicantAppId
      } else {
        id = this.props.location.state.id;
      }
      
      console.log("ID == "+id);

      this.setId(id);
      
      let myInit = { // OPTIONAL
            headers: {
              Authorization: jwtToken
            }, // OPTIONAL
            response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
            queryStringParameters: {  // OPTIONAL
              id: id
            }
          }
      API.get(apiName, path, myInit).then(response => {
        console.log("Respone : " + JSON.stringify(response));

        let dbSteps = response.Items[0].application;
        // let createdByApplicant = response.Items[0].createdBy && response.Items[0].createdBy.indexOf("#APPLICANT") !== -1;
        this.updateSteps(dbSteps);
        this.setState({
          disabled : dbSteps.status === "Locked" || 
              (this.props.location.state && this.props.location.state.disabled) ||
              dbSteps.status === "Ready" ||
              dbSteps.status === "Submitted",
          status: dbSteps.status
        }); 
      }).catch(error => {
        console.log(error.response ? error.response : error);
      });
    } else {
        this.setState({disabled : false}); 
    }
  }

  // componentDidMount() is invoked immediately after a component is mounted. Initialization that requires DOM nodes should go here. 
  // If you need to load data from a remote endpoint, this is a good place to instantiate the network request. Setting state in this method will trigger a re-rendering.
  async componentDidMount() {
    const user = await Auth.currentAuthenticatedUser();   
    console.log("Wizard did mount, User email "+ user.attributes.email);
    let admin = user.attributes["custom:userType"].indexOf("ADMIN") !== -1;
    let applicant = user.attributes["custom:userType"].indexOf("APPLICANT") !== -1;
    let rep = user.attributes["custom:userType"].indexOf("REP") !== -1 && user.attributes["custom:userType"].indexOf("APPLICANT") === -1;
    user.admin = admin;
    user.rep = rep;
    user.applicant = applicant;


    this.setState({user: user});
  }



  render() {
    return (
      <>
        <PanelHeader size="sm" />
        <div className="content">
          {this.state.alert}
          <Col xs={12} md={10} className="mr-auto ml-auto">
            <ReactWizard
              steps={this.state.steps}
              //progressbar
              navSteps
              validate
              title={"Build Application - Status : "+(this.state.status ? this.state.status : "Draft")}
              description="This information will let us know more about you."
              headerTextCenter
              color="blue"
              finishButtonClasses="btn-wd"
              nextButtonClasses="btn-wd"
              previousButtonClasses="btn-wd"
              finishButtonClick={this.finishButtonClick}
              nextButtonClick={this.finishButtonClick}
              setId={this.setId}
              getId={this.getId}
              updateSteps={this.updateSteps}
              id={this.state.id}
              disabled={this.state.disabled}
              user={this.state.user}
            />
          </Col>
        </div>
      </>
    );
  }
}

export default withRouter(Wizard);
