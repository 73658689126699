/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import AdminDashboard from "views/Dashboard/AdminDashboard.jsx";
import AppListPage from "views/Pages/AppListPage.jsx";
// import EmpListPage from "views/Pages/EmpListPage.jsx";
import InviteApplicantPage from "views/Pages/InviteApplicantPage.jsx";
import Wizard from "views/Forms/Wizard/Wizard.jsx";
import InterviewWizard from "views/Forms/Interview/Wizard.jsx";

let repRoutes = [
  
  
  {
    collapse: true,
    path: "/forms",
    name: "Applicant",
    state: "openForms",
    icon: "now-ui-icons design_bullet-list-67",
    views: [
      {
        path: "/app-list",
        name: "Applicants",
        mini: "AL",
        component: AppListPage,
        layout: "/rep"
      },
      {
        path: "/wizard",
        name: "Create Applicant",
        mini: "CA",
        component: Wizard,
        layout: "/rep"
      },
      {
        path: "/interview/wizard",
        name: "Interview Applicant",
        mini: "IA",
        component: InterviewWizard,
        layout: "/rep",
        invisable: true 
      },
      {
        path: "/applicant/invite",
        name: "Invite Applicant",
        mini: "AI",
        component: InviteApplicantPage,
        layout: "/rep",
      }
   ]
  }
];

export default repRoutes;
