/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//import AdminDashboard from "views/Dashboard/AdminDashboard.jsx";
// import EmpListPage from "views/Pages/EmpListPage.jsx";
import Wizard from "views/Forms/Wizard/Wizard.jsx";

let applRoutes = [
  {
    path: "/application",
    name: "Application",
    icon: "now-ui-icons design_app",
    component: Wizard,
    layout: "/appl"
  }
];

export default applRoutes;
