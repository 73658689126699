import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { createBrowserHistory } from "history";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.3.0";
import "assets/css/demo.css";
import "assets/css/custom.css";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";
import adminRoutes from "adminRoutes.js";
import repRoutes from "repRoutes.js";
import applRoutes from "applRoutes.js";



const hist = createBrowserHistory();

class App extends Component {

  

  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null
  }

  setAuthStatus = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  setUser = user => {
    this.setState({ user: user });
  }

  async componentDidMount() {
    try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      console.log(session);
      const user = await Auth.currentAuthenticatedUser();
      this.setUser(user);
    } catch(error) {
      if (error !== 'No current user') {
        console.log(error);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,      
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser
    }
    
    return (
      !this.state.isAuthenticating &&
      <div className="App">
        <Router history={hist}>
            <Switch>
                <Route path="/admin" render={props => {return this.state.isAuthenticated ? <AdminLayout routes={adminRoutes} {...props} auth={authProps}/> : <Redirect to="/auth/login-page" />;}} />
                <Route path="/rep" render={props => {return this.state.isAuthenticated ? <AdminLayout routes={repRoutes} {...props} auth={authProps}/> : <Redirect to="/auth/login-page" />;}} />
                <Route path="/appl" render={props => {return this.state.isAuthenticated ? <AdminLayout routes={applRoutes} {...props} auth={authProps}/> : <Redirect to="/auth/login-page" />;}} />
                <Route path="/auth" render={props => {return <AuthLayout {...props} authPro={authProps}/>;}}/>
                {/* <Redirect to="/admin/dashboard" /> */}
                <Redirect to="/auth/login-page" />
            </Switch>
        </Router>
      </div>
    );
  }
}

export default App;