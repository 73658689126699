/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";

import Switch from "react-bootstrap-switch";

class MedicalInformation extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      medicalInformation: "",
      sexualAbuse: false,
      sexualAbuseInformation: "",
      additionalInformation: ""
    };

    this.valueChange = this.valueChange.bind(this);
  }

  
  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  isValidated() {
    return true;
  }

  handleSwitch(elem, state) {
    this.setState({[elem.props.name]: state });
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    // if (nextProps.firstname !== this.state.firstname) {
    //   this.setState({ firstname: nextProps.firstname });
    // }
    // console.log("EM CReceviing props "+JSON.stringify(nextProps));

    //let state = Object.assign({}, this.state);

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      let newProps = Object.assign({}, nextProps);
      delete newProps.user;
      delete newProps.disabled;
      this.setState({ ...newProps });
    }
  }


  render() {
    return (
      <>
        <h5 className="info-text">
          {" "}
          Medical Information
        </h5>
     
        <Row className="justify-content-center">
          <Col xs={12} sm={10}>
            <FormGroup>
              <Label>Do you suffer or have ever suffered from any medical conditions?</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.medicalInformation || ''}
                  placeholder="Please describe..."
                  name="medicalInformation"
                  onChange={this.valueChange}
                  type="textarea"
                  
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
               <Label>Have you ever been a victim of sexual abuse?</Label>
               <br/>
               <Switch defaultValue={false}
                disabled={this.props.disabled}
                value={this.state.sexualAbuse}
                name="sexualAbuse"
                onChange={(el, state) => this.handleSwitch(el, state)}
                onText="Yes"
                offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
              <Label>If so, please provide additional information:</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.sexualAbuseInformation || ''}
                  placeholder="Additional Information"
                  name="sexualAbuseInformation"
                  onChange={this.valueChange}
                  type="textarea"
                  
                />
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
              <Label>Any additional information on your medical information or conditions:</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.additionalInformation || ''}
                  placeholder="Additional Medical Information..."
                  name="additionalInformation"
                  onChange={this.valueChange}
                  type="textarea"
                  
                />
              </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default MedicalInformation;
