/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";

import Switch from "react-bootstrap-switch";


class BackgroundCheck extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      caution: false,
      arrested: false,
      violentCrime: false,
      drugCrime: false,
      sexualOffence: false,
      courtJudgement: false,
      policeAction: false,
      conductBackgroundCheck: false,
      objectionReason: ""
    };

    this.valueChange = this.valueChange.bind(this);
  }

  
  handleSwitch(elem, state) {
    this.setState({[elem.props.name]: state });
  }

  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  isValidated() {
    return true;
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    // if (nextProps.firstname !== this.state.firstname) {
    //   this.setState({ firstname: nextProps.firstname });
    // }
    // console.log("EM CReceviing props "+JSON.stringify(nextProps));

    //let state = Object.assign({}, this.state);

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      let newProps = Object.assign({}, nextProps);
      delete newProps.user;
      delete newProps.disabled;
      this.setState({ ...newProps });
    }
  }


  render() {
    return (
      <>
        <h5 className="info-text">
          {" "}
          Confidential Background Check
        </h5>
     
        <Row className="justify-content-center">
          <Col xs={12} sm={5}>
            <FormGroup>
               <Label>Have you ever been the subject of a Caution of Bound over Order?</Label>
               <br/>
               <Switch defaultValue={false}
                  disabled={this.props.disabled}
                  value={this.state.caution}
                  name="caution"
                  onChange={(el, state) => this.handleSwitch(el, state)}
                  onText="Yes"
                  offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
               <Label>Have you ever been arrested?</Label>
               <br/>
               <Switch defaultValue={false}
                  disabled={this.props.disabled}
                  value={this.state.arrested}
                  name="arrested"
                  onChange={(el, state) => this.handleSwitch(el, state)}
                  onText="Yes"
                  offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
               <Label>Have you ever been convicted of a violent offence or crime?</Label>
               <br/>
               <Switch defaultValue={false}
                  disabled={this.props.disabled}
                  value={this.state.violentCrime}
                  name="violentCrime"
                  onChange={(el, state) => this.handleSwitch(el, state)}
                  onText="Yes"
                  offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
               <Label>Have you ever been convicted of a drug-related offence or crime?</Label>
               <br/>
               <Switch defaultValue={false}
                  disabled={this.props.disabled}
                  value={this.state.drugCrime}
                  name="drugCrime"
                  onChange={(el, state) => this.handleSwitch(el, state)}
                  onText="Yes"
                  offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
               <Label>Have you ever been convicted of a sexual offence or crime?</Label>
               <br/>
               <Switch defaultValue={false}
                  disabled={this.props.disabled}
                  value={this.state.sexualOffence}
                  name="sexualOffence"
                  onChange={(el, state) => this.handleSwitch(el, state)}
                  onText="Yes"
                  offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
               <Label>Is the applicant awaiting notification of a court judgement?</Label>
               <br/>
               <Switch defaultValue={false}
                  disabled={this.props.disabled}
                  value={this.state.courtJudgement}
                  name="courtJudgement"
                  onChange={(el, state) => this.handleSwitch(el, state)}
                  onText="Yes"
                  offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
               <Label>Is the applicant awaiting notification of a police action against him/her?</Label>
               <br/>
               <Switch defaultValue={false}
                  disabled={this.props.disabled}
                  value={this.state.policeAction}
                  name="policeAction"
                  onChange={(el, state) => this.handleSwitch(el, state)}
                  onText="Yes"
                  offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={5}>
            <FormGroup>
               <Label>Does the applicant object to AWE conducting a background check on him/her?</Label>
               <br/>
               <Switch defaultValue={false}
                  disabled={this.props.disabled}
                  value={this.state.conductBackgroundCheck}
                  name="conductBackgroundCheck"
                  onChange={(el, state) => this.handleSwitch(el, state)}
                  onText="Yes"
                  offText="No"/>
              </FormGroup>
          </Col>
          <Col xs={12} sm={10}>
            <FormGroup>
              <Label>If you object to AWE conducting a background check, please provide additional information:</Label>
              <Input
                  disabled={this.props.disabled}
                  value={this.state.objectionReason || ''}
                  placeholder="Reason for objecting to AWE conducting a background check..."
                  name="objectionReason"
                  onChange={this.valueChange}
                  type="textarea"
                />
              </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default BackgroundCheck;
