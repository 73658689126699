/*!

=========================================================
* Now UI Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label
} from "reactstrap";

import Datetime from "react-datetime";
import { Storage } from 'aws-amplify';

class Interview_1 extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      interviewDate: "",
      repsPhoneNumber: "",
      englishTest: "",
      nameOfTest: "",
      dateTaken: ""
    };

    this.valueChange = this.valueChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  

  setProgressValue(pValue) {
    

    console.log("PValue "+this.state.progressValue);
  }

  handleDownload = async event => {
    event.preventDefault();
    console.log("Download File");
    Storage.get(this.state.file.name, {expires: 60})
      .then(result => {
          console.log(result)
          //let blob=new Blob([data.Body], {type: data.ContentType});
          let link=document.createElement('a');
          link.href=result;
          link.download=result;
          link.click();
      
      
      })
      .catch(err => console.log(err));
  }

  valueChange(e) {
    this.setState({
      [e.target.name] : e.target.value
    });
  }

  

  isValidated() {
    
    return true;
  }


  handleDateChange(field, date) {
   
    if(date instanceof Date) {
        this.setState({ [field]: date.valueOf() });  
    } else {
      this.setState({ [field]: "" });  
    }
    
   }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render

    if(nextProps.wizardData && Object.keys(nextProps.wizardData).length !== 0) {
      let state = Object.assign({}, this.state);
      state.wizardData = nextProps.wizardData;
      this.setState(state);
    } else {
      this.setState({ ...nextProps });
    }
  }


  render() {
    //console.log("********************* personal info rendering" + JSON.stringify(this.state));
    return (
      <>
        <h5 className="info-text">
          {" "}
          Interviewers Report
        </h5>
        <Row className="justify-content-center">
            
            <Col className="checkbox-radios" sm="2">
              <Label>Programme</Label>
              <FormGroup  check className="form-check-radio">
                <Label check>
                <Input                       
                      defaultValue="Counsellor"
                      name="programme"
                      onChange={this.valueChange}
                      checked={this.state.programme === "Counsellor"}
                      type="radio"/>
                  <span className="form-check-sign" />Counsellor
                </Label>
              </FormGroup>
              <FormGroup  check className="form-check-radio">
                <Label check> 
                <Input                       
                      defaultValue="SupportStaff"
                      name="programme"
                      onChange={this.valueChange}
                      checked={this.state.programme === "SupportStaff"}
                      type="radio"/>
                  <span className="form-check-sign" />Support Staff
                </Label>
              </FormGroup>
              <FormGroup  check className="form-check-radio">
                <Label check>
                <Input                       
                      defaultValue="SummerWorkTravel"
                      name="programme"
                      onChange={this.valueChange}
                      checked={this.state.programme === "SummerWorkTravel"}
                      type="radio"/>
                  <span className="form-check-sign" />Summer Work Travel
                </Label>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6}>
                <FormGroup>
                  <Label>Interview Date</Label>
                  <Datetime
                          value={this.state.interviewDate || ''}
                          timeFormat={false}
                          
                          dateFormat="YYYY/MM/DD"
                          closeOnSelect={true}
                          inputProps={{ placeholder: "Interview Date"  }}
                          onChange={(e) => this.handleDateChange("interviewDate", e)}
                        />
                </FormGroup>
                <FormGroup>
                  <Label>Representative’s Phone number:</Label>
                  <Input 
                    value={this.state.repsPhoneNumber || ''}
                    name="repsPhoneNumber"
                    type="text"
                    placeholder="Phone Number"
                    onChange={this.valueChange}
                  />
                </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
              <Col sm="8">
                <Label>If English is not the first language, please tick the appropriate box and comment on the following:</Label>
                <br/>
                <Label sm="2">Accent   </Label>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="unnoticeable"
                      name="accent"
                      onChange={this.valueChange}
                      checked={this.state.accent === "unnoticeable"}
                      type="radio"/>
                    <span className="form-check-sign" />unnoticeable
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="acceptable"
                      name="accent"
                      onChange={this.valueChange}
                      checked={this.state.accent === "acceptable"}
                      type="radio"/>
                    <span className="form-check-sign" />acceptable
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                  <Input                       
                      defaultValue="strong"
                      name="accent"
                      onChange={this.valueChange}
                      checked={this.state.accent === "strong"}
                      type="radio"/>
                    <span className="form-check-sign" />strong
                  </Label>
                </FormGroup>
              </Col>
          </Row>
          <Row className="justify-content-center">
              <Col sm="8">
                <Label sm="2">Comprehension</Label>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="excellent"
                      name="comprehension"
                      onChange={this.valueChange}
                      checked={this.state.comprehension === "excellent"}
                      type="radio"/>
                    <span className="form-check-sign" />excellent
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="good"
                      name="comprehension"
                      onChange={this.valueChange}
                      checked={this.state.comprehension === "good"}
                      type="radio"/>
                    <span className="form-check-sign" />good
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="fair"
                      name="comprehension"
                      onChange={this.valueChange}
                      checked={this.state.comprehension === "fair"}
                      type="radio"/>
                  <span className="form-check-sign" />fair
                  </Label>
                </FormGroup>
              </Col>
          </Row>
          <Row className="justify-content-center">
              <Col sm="8">
                <Label sm="2">Response to questions</Label>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="fast"
                      name="resToQ"
                      onChange={this.valueChange}
                      checked={this.state.resToQ === "fast"}
                      type="radio"/>
                    <span className="form-check-sign" />fast
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="acceptable"
                      name="resToQ"
                      onChange={this.valueChange}
                      checked={this.state.resToQ === "acceptable"}
                      type="radio"/>
                    <span className="form-check-sign" />acceptable
                  </Label>
                </FormGroup>
                <FormGroup check inline className="form-check-radio">
                  <Label check>
                    <Input                       
                      defaultValue="slow"
                      name="resToQ"
                      onChange={this.valueChange}
                      checked={this.state.resToQ === "slow"}
                      type="radio"/>
                    <span className="form-check-sign" />slow
                  </Label>
                </FormGroup>
              </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} sm={8}>
                <FormGroup>
                  <Label>Results of any English Language Test taken within the past 12 months:</Label>
                  <Input 
                    value={this.state.englishTest || ''}
                    name="englishTest"
                    type="text"
                    placeholder="Result"
                    onChange={this.valueChange}
                  />
                </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={12} sm={4}>
                <FormGroup>
                  <Label>Name of Test</Label>
                  <Input 
                    value={this.state.nameOfTest || ''}
                    name="nameOfTest"
                    type="text"
                    placeholder="Name of Test"
                    onChange={this.valueChange}
                  />
                </FormGroup>
            </Col>
            <Col xs={12} sm={4}>
                <FormGroup>
                  <Label>Date Taken</Label>
                  <Datetime
                          value={this.state.dateTaken || ''}
                          dateFormat="YYYY/MM/DD"
                          timeFormat={false}
                          closeOnSelect={true}
                          inputProps={{ placeholder: "Date Taken"  }}
                          onChange={(e) => this.handleDateChange("dateTaken", e)}
                        />
                </FormGroup>
            </Col>
          </Row>
          
      </>
    );
  }
}

export default Interview_1;
